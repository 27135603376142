import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { INestedMenuItem, NestedMenuItem } from 'src/app/models/menu.model';
import { GlobalService } from 'src/app/services/global.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CodeTableService } from 'src/app/services/code-table.service';
import { ECodeTables, EFilterCategories } from 'src/app/util/enum';
import {CompositeList, WeightRange} from 'src/app/models/filter.model';

/**
 * Asset Class Filter Details Component.
 * Component for filter details for asset class category.
 */
@Component({
  selector: 'app-filter-details-asset-class',
  templateUrl: './filter-details-asset-class.component.html',
})
export class FilterDetailsAssetClassComponent implements OnInit {
  /**
   * Component input that references asset class range filter in filter config
   */
  @Input() filterForm: CompositeList<WeightRange>;

  @Input() readOnly = false;
  /**
   * All possible asset classes
   */
  assetClasses: INestedMenuItem[] = [];
  /**
   * Currently available assets classes
   */
  availableAssetClasses: INestedMenuItem[] = [];

  get filterCategories() {
    return EFilterCategories;
  }

  constructor(
    protected globalService: GlobalService,
    protected codeTableService: CodeTableService,
    protected notificationService: NotificationService,
    @Inject(LOCALE_ID) protected locale: string
  ) {}

  ngOnInit(): void {
    const existingEntries = this.filterForm.children.map(d => d.key);
    this.codeTableService
      .getCodeTable(ECodeTables.assetClass)
      .subscribe((data) => {
        this.assetClasses = data.map(
          (ac) => {
            if (!ac.closed || existingEntries.includes(ac.ident)) {
              return new NestedMenuItem(ac.ident, ac.name);
            }
            return null;
          }
        ).filter(d => d);
        this.availableAssetClasses = this.getAvailableAssetClasses();
      });
  }

  /**
   * Get available asset class options
   */
  private getAvailableAssetClasses(): INestedMenuItem[] {
    return this.assetClasses
      .map((ac) => this.globalService.deepCopyNestedMenuItems(ac))
  }

  /**
   * Adds filter with provided key
   * @param assetClass Key of asset class filter
   */
  addFilter(assetClass: INestedMenuItem): void {
    this.filterForm.children.push({
      key: assetClass.key,
      range: {
        max: null,
        min: null,
      },
      weight: null,
      unbundledType: 'direct',
    });
  }

  /**
   * Deletes filter with provided key
   * @param assetClass Key of asset class filter
   */
  deleteFilter(assetClass: string): void {
    const index = this.filterForm.children.findIndex(
      (filter) => filter.key === assetClass
    );
    this.filterForm.children.splice(index, 1);
  }

  /**
   * Get label based on key
   * @param key Filter key
   */
  getLabel(key: string): string {
    return this.assetClasses.find((ac) => ac.key === key).label;
  }

  changeOperator($event: string) {
    $event === 'and' ? this.filterForm.operator = 'and' : this.filterForm.operator = 'mor';
  }
}
