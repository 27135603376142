import {Component, HostBinding} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import {TranslateService} from '@ngx-translate/core';
import {commonFilterParams, commonParams,} from 'src/app/util/grid/grid-renderer.util';
import {GridIconLabelInfo} from 'src/app/models/grid.model';
import {ColDef, ICellRendererParams} from "ag-grid-community";

@Component({
  template: `
    <ng-container *ngIf="!(hidden | isRendererHidden: data)">
      <app-config-icon
        *ngIf="icon && align != 'right' && align != 'suffix'"
        class="{{ iconClass }}"
        [iconIdentifier]="this.icon"
        [tooltip]="iconTooltip"
        [outlined]="outlined"
        (click)="callback && callback(data, action)"
      ></app-config-icon>
      <ng-container *ngIf="label">
        <span *ngIf="!labelCallback" class="label label-icon">
          <span>{{ label }}</span>
          <app-config-icon
            *ngIf="icon && align == 'suffix'"
            class="{{ iconClass }}"
            [iconIdentifier]="this.icon"
            [tooltip]="iconTooltip"
            [outlined]="outlined"
            (click)="callback && callback(data, action)"
          ></app-config-icon>
        </span>
        <ng-container *ngIf="labelCallback">
          <span class="color-primary ag-cell-value">
            <span class="link-label label-icon" (click)="labelCallback(data, action)" [matTooltip]="tooltip | translate">
              <span>{{ label }}</span>
              <app-config-icon
                *ngIf="icon && align == 'suffix'"
                class="{{ iconClass }}"
                [iconIdentifier]="this.icon"
                [tooltip]="iconTooltip"
                [outlined]="outlined"
                (click)="callback && callback(data, action)"
              ></app-config-icon>
            </span>
          </span>
        </ng-container>
      </ng-container>
      <app-config-icon
        *ngIf="icon && align == 'right'"
        class="{{ iconClass }}"
        [iconIdentifier]="this.icon"
        [tooltip]="iconTooltip"
        [outlined]="outlined"
        (click)="callback && callback(data, action)"
      ></app-config-icon>
    </ng-container>
  `,
  styles: ['.link-label {cursor: pointer;}'],
})
export class IconLabelRenderer implements AgRendererComponent {
  @HostBinding('class.grid-cell-custom-content') theme = true;
  data: any;
  icon: string;
  action: string;
  tooltip: string;
  iconTooltip: string;
  label: string;
  iconClass: string;
  outlined = false;
  align = undefined;
  callback: (data: any, action: string) => void;
  labelCallback: (data: any, action: string) => void;
  hidden: (data: any) => boolean;

  constructor(protected translateService: TranslateService) {}

  agInit(params: ICellRendererParams): void {
    this.data = params.data;
    const info: GridIconLabelInfo =
      params?.colDef?.cellRendererParams?.actionsParams?.customContentInfo;
    if (typeof info.icon === 'function') {
      this.icon = info.icon(this.data);
    } else {
      this.icon = info.icon;
    }
    this.iconClass = info.iconClass;
    this.outlined = info.outlined || false;
    this.align = info.align || 'left';

    if (typeof info.label === 'function') {
      this.label = info.label(this.data);
    } else {
      this.label = info.label;
    }
    if (this.icon === 'info') {
      this.tooltip = this.translateService.instant('showDetails');
      this.action = 'info';
    } else {
      if (typeof info.tooltip === 'function') {
        this.tooltip = info.tooltip(this.data);
      } else {
        this.tooltip = info.tooltip;
      }
    }
    if (typeof info.iconTooltip === 'function') {
      this.iconTooltip = info.iconTooltip(this.data);
    } else if (info.iconTooltip) {
      this.iconTooltip = info.iconTooltip;
    }
    if (info) {
      this.callback = info.callback;
      this.hidden = info.hidden;
      this.labelCallback = info.labelCallback;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  protected readonly undefined = undefined;
}

export function genIconLabelColumn(info: GridIconLabelInfo): ColDef {
  return {
    ...commonParams(info.field, info?.headerName),
    cellRenderer: IconLabelRenderer,
    sortable: false,
    filterParams: {
      ...commonFilterParams(info.filterParamsInfo),
      filterOptions: [
        'contains',
        'notContains',
        'equals',
        'notEqual',
        'startsWith',
        'endsWith',
      ],
    },
    cellRendererParams: {
      actionsParams: {
        customContentInfo: info,
      },
      innerRenderer: IconLabelRenderer,
    },
  };
}
