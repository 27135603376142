import {Component, Inject, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, finalize, first, Subscription} from 'rxjs';
import {
  CodeTableEntry, ContentService, FieldDefinition, FieldDefinitionType,
  Salutation,
  SalutationService,
  TemplateDefault,
  TemplateDefaultService,
} from '../../../../../api/core';
import {ModalSubComponent} from '../../../../../models/modal.model';
import {codeTableEntries, CodeTableService} from '../../../../../services/code-table.service';
import {GlobalService} from '../../../../../services/global.service';
import {NotificationService} from '../../../../../services/notification.service';
import {ModalComponent} from '../../../../../shared/modal/modal.component';
import {ECodeTables, EFormStatus, EModalType} from '../../../../../util/enum';
import {FroalaEditorService} from "../../../../../services/froala-editor.service";

@Component({
  selector: 'app-salutation-details',
  templateUrl: './salutation-details.component.html',
})
export class SalutationDetailsComponent
  implements OnDestroy, ModalSubComponent
{
  salutation: Salutation;
  existingSalutations: Salutation[] = [];
  languages: CodeTableEntry[] = [];
  channelTypes: CodeTableEntry[] = [];

  private subscriptions: Subscription[] = [];
  salutationForm: FormGroup;
  templates: TemplateDefault[] = [];
  fieldDefinition: FieldDefinition;
  editorOptions: any;
  private systemFieldDefinitions: FieldDefinition[] = [];

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly globalService: GlobalService,
    protected readonly codeTableService: CodeTableService,
    protected readonly salutationService: SalutationService,
    protected readonly notificationService: NotificationService,
    protected readonly templateDefaultService: TemplateDefaultService,
    protected readonly contentService: ContentService,
    protected readonly froalaEditorService: FroalaEditorService,
    protected readonly fb: FormBuilder,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: { salutation: Salutation; list: Salutation[] } }
  ) {
    combineLatest([
      codeTableService.getCodeTable(ECodeTables.language),
      codeTableService.getCodeTable(ECodeTables.channelType),
      templateDefaultService.findAll(),
      contentService.getFieldDefinitions(),
    ]).subscribe(([languages, channelTypes, templateDefaults, fieldDefinitions]) => {
      this.languages = languages;
      this.channelTypes = codeTableEntries(channelTypes);
      this.templates = templateDefaults;
      this.systemFieldDefinitions = fieldDefinitions.filter(f => f.systemDefined);
      this.handleSalutation(data.data.salutation);
      this.existingSalutations = data.data.list;
    });
  }

  modalAction(modalType: EModalType | undefined): void {
    const toSave = {
      ...this.salutation,
      ...(this.salutationForm.getRawValue() as Salutation),
    } as Salutation;
    if (!this.isValid(toSave)) {
      this.notificationService.handleWarning(
        this.translateService.instant('duplicateSalutationWarning')
      );
      this.toggleSaveButton(true);
      return;
    }
    switch (modalType) {
      case EModalType.createIntro:
      case EModalType.createOutro:
        this.salutationService
          .create(toSave)
          .pipe(
            first(),
            finalize(() => this.toggleSaveButton(true))
          )
          .subscribe({
            next: () => {
              this.notificationService.handleSuccess(
                this.translateService.instant('createSalutationSuccess')
              );
              this.dialogRef.close();
            },
            error: (err) => {
              this.notificationService.handleError(err);
            }
          });
        break;
      case EModalType.editIntro:
      case EModalType.editOutro:
        this.salutationService
          .updateById(toSave.id, toSave)
          .pipe(
            first(),
            finalize(() => this.toggleSaveButton(true))
          )
          .subscribe({
            next: () => {
              this.notificationService.handleSuccess(
                this.translateService.instant('updateSalutationSuccess')
              );
              this.dialogRef.close();
            },
            error: (err) => {
              this.notificationService.handleError(err);
            }
          });
        break;
    }
  }

  private handleSalutation(salutation: Salutation) {
    if (salutation) {
      this.salutation = salutation;
      this.fieldDefinition = this.systemFieldDefinitions.find(f => f.ident === salutation.salutationType);
      const label = this.translateService.currentLang === 'de' ?
        this.fieldDefinition.labelEn : this.fieldDefinition.labelDe;
      this.editorOptions = this.froalaEditorService.getEditorOptions(this.fieldDefinition);
      this.salutationForm = this.fb.group({
        language: [null as CodeTableEntry, Validators.required],
        channelType: new FormControl({
          value: null,
          disabled: this.salutation.isDefault,
        }),
        value: [''],
      });
      let channelType = salutation.channelType;
      this.channelTypes = codeTableEntries(this.channelTypes, channelType);
      channelType = channelType ?
        this.channelTypes.find((t) => t.id === salutation.channelType.id) : undefined;
      this.salutationForm.patchValue({
        language: this.languages.find((t) => t.id === salutation.language?.id),
        channelType,
        value: salutation.value,
      });
      this.registerFormSubscriptions();
    }
  }

  protected updateCodeTables() {
    this.channelTypes = codeTableEntries(this.channelTypes, this.salutationForm.value.channelType);
  }

  private registerFormSubscriptions(): void {
    this.toggleSaveButton(this.salutationForm.controls.language.valid);

    this.subscriptions.push(
      this.salutationForm.statusChanges.subscribe((status) =>
        this.toggleSaveButton(status === EFormStatus.VALID)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private isValid(toSave: Salutation) {
    let result: boolean;
    const existing = this.existingSalutations.find(
      (d) =>
        d.salutationType === toSave.salutationType &&
        d.language.id === toSave.language.id &&
        d.channelType?.id === toSave.channelType?.id
    );
    if (toSave.id) {
      result = !existing || existing.id === toSave.id;
    } else {
      result = !existing;
    }
    return result;
  }

  private toggleSaveButton(valid: boolean) {
    this.dialogRef.componentInstance.resetToolbarActionButtons();
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = !valid;
  }

  protected readonly FieldDefinitionType = FieldDefinitionType;
}
