import {Component, Inject, Input, LOCALE_ID, OnInit, ViewChild,} from '@angular/core';
import {AssetRange, CompositeList} from 'src/app/models/filter.model';
import {GlobalService} from 'src/app/services/global.service';
import {NotificationService} from 'src/app/services/notification.service';
import {IKeyValue} from 'src/app/models/key-value.model';
import {FilterComponent} from '../../filter/filter.component';
import {
  productSearchDialog,
} from "../../../product/shared/product-search-dialog/product-search-dialog.component";
import {ModalService} from "../../../services/modal.service";
import {TranslateService} from "@ngx-translate/core";
import {GuiService, Product} from "../../../api/core";

/**
 * Asset Class Filter Details Component.
 * Component for filter details for asset class category.
 */
@Component({
  selector: 'app-filter-details-asset',
  templateUrl: './filter-details-asset.component.html',
})
export class FilterDetailsAssetComponent implements OnInit {
  @ViewChild('autoCompleteFilter') autoCompleteFilter: FilterComponent;
  /**
   * Component input that references asset class range filter in filter config
   */
  @Input() assetsInclude: CompositeList<AssetRange>;
  @Input() assetsExclude: CompositeList<AssetRange>;

  @Input() readOnly = false;
  /**
   * Currently available assets classes
   */
  _search: IKeyValue = null;
  maxAssets: number = 20;

  get search(): IKeyValue {
    return this._search;
  }

  set search(value: IKeyValue) {
    this._search = value;
  }

  constructor(
    protected globalService: GlobalService,
    protected notificationService: NotificationService,
    protected modalService: ModalService,
    protected translateService: TranslateService,
    protected guiService: GuiService,
    @Inject(LOCALE_ID) protected locale: string
  ) {
    guiService.getConfig()
      .subscribe(gui => {
        this.maxAssets = +gui.productSearch.maxSelectedAssets;
      });
  }

  ngOnInit() {}

  /**
   * Deletes filter with provided key
   * @param key Key of asset class filter
   */
  deleteFilterInclude(key: string): void {
    const index = this.assetsInclude.children.findIndex((filter) => filter.key === key);
    this.assetsInclude.children.splice(index, 1);
  }
  deleteFilterExclude(key: string): void {
    const index = this.assetsExclude.children.findIndex((filter) => filter.key === key);
    this.assetsExclude.children.splice(index, 1);
  }

  handleAddInclude() {
    productSearchDialog(
      this.modalService,
      'Add Product',
      this.translateService.instant('add'),
      this.translateService.instant('cancel'),
      this.maxAssets,
    ).subscribe(result => {
      if (!result) return;
      const selected = (result.selected as Product[])
        .map(p => ({
          key: p.isin,
          name: p.name,
          range: {
            min: 0,
            max: 100,
          },
          isValue: false,
          hasExposure: false,
          unbundledType: 'direct',
        } as AssetRange));
      this.assetsInclude.children.push(
        ...selected
      ); // add only new ones
    });
  }

  handleAddExclude() {
    productSearchDialog(
      this.modalService,
      'Add Product',
      this.translateService.instant('add'),
      this.translateService.instant('cancel'),
      this.maxAssets,
    ).subscribe(result => {
      if (!result) return;
      const selected = (result.selected as Product[])
        .map(p => ({
          key: p.isin,
          name: p.name,
          range: {
            min: 0,
            max: 100,
          },
          isValue: false,
          hasExposure: false,
          unbundledType: 'direct',
        } as AssetRange));
      this.assetsExclude.children.push(
        ...selected
      ); // add only new ones
    })
  }

  changeOperatorInclude($event: string) {
    $event === 'and' ? this.assetsInclude.operator = 'and' : this.assetsInclude.operator = 'mor';
  }
  changeOperatorExclude($event: string) {
    $event === 'and' ? this.assetsExclude.operator = 'and' : this.assetsExclude.operator = 'mor';
  }
}
