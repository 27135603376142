<div class="card {{active ? activeClass : ''}}" [class.content-updated]="updated">
  <div class="update-flash" *ngIf="updated"></div>
  <div class="card-header-container" *ngIf="showHeader">
    <div class="card-header h4">
      <ng-content select="[card-header]"></ng-content>
    </div>
    <div class="card-actions">
      <ng-content select="[card-action]"></ng-content>
    </div>
  </div>
  <div class="card-body">
    <ng-content select="[card-body]"></ng-content>
  </div>
  <div class="card-footer"></div>
  <div class="spinner-container" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
