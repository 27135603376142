import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { NestedMenuItem, INestedMenuItem } from 'src/app/models/menu.model';
import { NotificationService } from 'src/app/services/notification.service';
import { CodeTableService } from 'src/app/services/code-table.service';
import { ECodeTables, EFilterCategories } from 'src/app/util/enum';
import {CompositeList, WeightRange} from "../../../models/filter.model";

/**
 * Region Filter Details Component.
 * Component for filter details for region category.
 */
@Component({
  selector: 'app-filter-details-region',
  templateUrl: './filter-details-region.component.html',
})
export class FilterDetailsRegionComponent implements OnInit {
  /**
   * Component input that references region range filter in filter config
   */
  @Input() filterForm: CompositeList<WeightRange>;

  @Input() readOnly = false;

  /**
   * ALl possible regions
   */
  regions: INestedMenuItem[] = [];
  /**
   * Currently still available regions
   */
  availableRegions: INestedMenuItem[] = [];

  get filterCategories() {
    return EFilterCategories;
  }

  constructor(
    protected codeTableService: CodeTableService,
    protected notificationService: NotificationService,
    @Inject(LOCALE_ID) protected locale: string
  ) {}

  ngOnInit(): void {
    this.codeTableService.getCodeTable(ECodeTables.region).subscribe((data) => {
      this.regions = data.map(
        (region) => new NestedMenuItem(region.ident, region.name)
      );
      this.availableRegions = this.regions
    });
  }

  /**
   * Adds filter with provided key
   * @param region Key of region filter
   */
  addFilter(region: INestedMenuItem): void {
    this.filterForm.children.push({
      key: region.key,
      range: {
        max: null,
        min: null,
      },
      weight: null,
      unbundledType: 'direct',
    });
  }

  /**
   * Deletes filter with provided key
   * @param region Key of region filter
   */
  deleteFilter(region: string): void {
    const index = this.filterForm.children.findIndex((filter) => filter.key === region);
    this.filterForm.children.splice(index, 1);
  }

  /**
   * Get label based on key
   * @param key Filter key
   */
  getLabel(key: string): string {
    return this.regions.find((r) => r.key === key).label;
  }

  changeOperator($event: string) {
    $event === 'and' ? this.filterForm.operator = 'and' : this.filterForm.operator = 'mor';
  }
}
