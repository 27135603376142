<mat-expansion-panel class="report-body">
  <mat-expansion-panel-header class="report-body-header">
    <mat-panel-title>
      <span *ngIf="!key">{{entity}}</span>
      <span *ngIf="key">{{key}}: {{entity}}</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <button type="button" mat-raised-button color="warn" *ngIf="key" class="report-remove" (click)="onRemove.emit(key)">
    <app-config-icon iconIdentifier="delete"></app-config-icon>
    {{'remove' | translate}}
  </button>
  <mat-expansion-panel class="report-fields" (opened)="fieldsOpenState.set(true)" (closed)="fieldsOpenState.set(false)" >
    <mat-expansion-panel-header>
      <mat-panel-title>Fields ({{ body.fields.length }})</mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-chip-listbox multiple>
      <mat-chip-option *ngFor="let f of fields" [selected]="f.selected" (selectionChange)="onFieldSelected(f.value, $event.selected)" [matTooltip]="f.tooltip">
        {{f.text}}
      </mat-chip-option>
    </mat-chip-listbox>
  </mat-expansion-panel>
  <mat-expansion-panel class="report-entities" (opened)="entitiesOpenState.set(true)" (closed)="entitiesOpenState.set(false)" >
    <mat-expansion-panel-header>
      <mat-panel-title>Entities ({{bodyEntities.length}})</mat-panel-title>
    </mat-expansion-panel-header>
    <button type="button" mat-raised-button [matMenuTriggerFor]="add_entity" *ngIf="addEntities.length > 0" >
      <app-config-icon iconIdentifier="add"></app-config-icon>
      {{'add' | translate}}
    </button>
    <mat-menu #add_entity=matMenu>
      <button mat-menu-item *ngFor="let entity of addEntities" (click)="addEntity(entity)">{{entity}}</button>
    </mat-menu>
    <app-report-body *ngFor="let child of bodyEntities"
                     [key]="child.key"
                     [entity]="child.name"
                     [originalSchema]="originalSchema"
                     [schema]="child.spec"
                     [body]="child.body"
                     (onRemove)="removeEntity($event)"
    ></app-report-body>
  </mat-expansion-panel>
  <mat-expansion-panel class="report-filters" (opened)="filtersOpenState.set(true)" (closed)="filtersOpenState.set(false)">
    <mat-expansion-panel-header>
      <mat-panel-title>Filters ({{bodyFilters.length}})</mat-panel-title>
    </mat-expansion-panel-header>
    <button type="button" mat-raised-button [matMenuTriggerFor]="add_filter" *ngIf="addEntities.length > 0">
      <app-config-icon iconIdentifier="add"></app-config-icon>
      {{'add' | translate}}
    </button>
    <mat-menu #add_filter=matMenu>
      <button mat-menu-item *ngFor="let filter of addFilters" (click)="addFilter(filter)">{{filter}}</button>
    </mat-menu>
    <mat-chip-listbox>
      <mat-chip *ngFor="let f of bodyFilters" (click)="editFilter(f.field)">
        {{f.value}}
        <button matChipRemove (click)="removeFilter(f.field)">
          <app-config-icon iconIdentifier="close"></app-config-icon>
        </button>
      </mat-chip>
    </mat-chip-listbox>
  </mat-expansion-panel>
  <mat-expansion-panel class="report-sort-by" (opened)="sortByOpenState.set(true)" (closed)="sortByOpenState.set(false)">
    <mat-expansion-panel-header>
      <mat-panel-title>Sort By ({{bodySortBy.length}})</mat-panel-title>
    </mat-expansion-panel-header>
    <button type="button" mat-raised-button [matMenuTriggerFor]="add_sorts" *ngIf="addSorts.length > 0">
      <app-config-icon iconIdentifier="add"></app-config-icon>
      {{'add' | translate}}
    </button>
    <mat-menu #add_sorts=matMenu>
      <button mat-menu-item *ngFor="let s of addSorts" (click)="addSortBy(s)">{{s}}</button>
    </mat-menu>
    <mat-chip-listbox>
      <mat-chip *ngFor="let s of bodySortBy" (click)="editSortBy(s.field)">
        {{s.field}} {{s.desc ? 'DESC' : 'ASC'}}
        <button matChipRemove (click)="removeSortBy(s.field)">
          <app-config-icon iconIdentifier="close"></app-config-icon>
        </button>
      </mat-chip>
    </mat-chip-listbox>
  </mat-expansion-panel>
  <mat-expansion-panel class="limit" (opened)="limitOpenState.set(true)" (closed)="limitOpenState.set(false)">
    <mat-expansion-panel-header>
      <mat-panel-title>Limit {{body.limit != null ? body.limit : ''}}</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field [formGroup]="form">
      <mat-label>Limit</mat-label>
      <input type="number" formControlName="limit" matInput />
    </mat-form-field>
  </mat-expansion-panel>
</mat-expansion-panel>
