export const ICONS_CONFIG = {
  campaign: {
    name: 'campaign',
    type: 'material',
  },
  wallet: {
    name: 'wallet',
    type: 'material',
  },
  bar_chart: {
    name: 'bar_chart',
    type: 'material',
  },
  home: {
    name: 'home',
    type: 'material',
  },
  lightbulb: {
    name: 'lightbulb',
    type: 'material',
  },
  logout: {
    name: 'logout',
    type: 'material',
  },
  clear: {
    name: 'clear',
    type: 'material',
  },
  analytics: {
    name: 'analytics',
    type: 'material',
  },
  outbound: {
    name: 'outbound',
    type: 'material',
  },
  hourglass_empty: {
    name: 'hourglass_empty',
    type: 'material',
  },
  article: {
    name: 'article',
    type: 'material',
  },
  search: {
    name: 'search',
    type: 'material',
  },
  add: {
    name: 'add',
    type: 'material',
  },
  remove: {
    name: 'remove',
    type: 'material',
  },
  remove_circle_outline: {
    name: 'remove_circle_outline',
    type: 'material',
  },
  local_offer: {
    name: 'local_offer',
    type: 'material',
  },
  sort: {
    name: 'sort',
    type: 'material',
  },
  arrow_downward: {
    name: 'arrow_downward',
    type: 'material',
  },
  arrow_upward: {
    name: 'arrow_upward',
    type: 'material',
  },
  list_alt: {
    name: 'list_alt',
    type: 'material',
  },
  list: {
    name: 'list',
    type: 'material',
  },
  whatshot: {
    name: 'whatshot',
    type: 'material',
  },
  date_range: {
    name: 'date_range',
    type: 'material',
  },
  place: {
    name: 'place',
    type: 'material',
  },
  tune: {
    name: 'tune',
    type: 'material',
  },
  delete: {
    name: 'delete',
    type: 'material',
  },
  edit_m: {
    name: 'edit',
    type: 'material',
  },
  delete_forever: {
    name: 'delete_forever',
    type: 'material',
  },
  menu_book: {
    name: 'menu_book',
    type: 'material',
  },
  verified: {
    name: 'verified',
    type: 'material',
  },
  new_releases: {
    name: 'new_releases',
    type: 'material',
  },
  table_chart: {
    name: 'table_chart',
    type: 'material',
  },
  title: {
    name: 'title',
    type: 'material',
  },
  insert_chart_outlined: {
    name: 'insert_chart_outlined',
    type: 'material',
  },
  cancel: {
    name: 'cancel',
    type: 'material',
  },
  save: {
    name: 'save',
    type: 'material',
  },
  save_alt: {
    name: 'save_alt',
    type: 'material',
  },
  save_as: {
    name: 'save_as',
    type: 'material',
  },
  post_add: {
    name: 'post_add',
    type: 'material',
  },
  check_circle_outline: {
    name: 'check_circle_outline',
    type: 'material',
  },
  add_circle_outline: {
    name: 'add_circle_outline',
    type: 'material',
  },
  add_circle: {
    name: 'add_circle',
    type: 'material',
  },
  check: {
    name: 'check',
    type: 'material',
  },
  landscape: {
    name: 'landscape',
    type: 'material',
  },
  picture_as_pdf: {
    name: 'picture_as_pdf',
    type: 'material',
  },
  report: {
    name: 'report',
    type: 'material',
  },
  info: {
    name: 'info',
    type: 'material',
  },
  warning: {
    name: 'warning',
    type: 'material',
  },
  warning_amber: {
    name: 'warning_amber',
    type: 'material',
  },
  error: {
    name: 'error',
    type: 'material',
  },
  check_box: {
    name: 'check_box',
    type: 'material',
  },
  check_box_outline_blank: {
    name: 'check_box_outline_blank',
    type: 'material',
  },
  assignment_late: {
    name: 'assignment_late',
    type: 'material',
  },
  assignment_turned_in: {
    name: 'assignment_turned_in',
    type: 'material',
  },
  thumb_up: {
    name: 'thumb_up',
    type: 'material',
  },
  thumb_down: {
    name: 'thumb_down',
    type: 'material',
  },
  more_vert: {
    name: 'more_vert',
    type: 'material',
  },
  double_arrow: {
    name: 'double_arrow',
    type: 'material',
  },
  chevron_right: {
    name: 'chevron_right',
    type: 'material',
  },
  arrow_right_alt: {
    name: 'arrow_right_alt',
    type: 'material',
  },
  flip_camera_android: {
    name: 'flip_camera_android',
    type: 'material',
  },
  trending_down: {
    name: 'trending_down',
    type: 'material',
  },
  trending_flat: {
    name: 'trending_flat',
    type: 'material',
  },
  trending_up: {
    name: 'trending_up',
    type: 'material',
  },
  help_outline: {
    name: 'help_outline',
    type: 'material',
  },
  open_in_new: {
    name: 'open_in_new',
    type: 'material',
  },
  arrow_back: {
    name: 'arrow_back',
    type: 'material',
  },
  filter_list: {
    name: 'filter_list',
    type: 'material',
  },
  refresh: {
    name: 'refresh',
    type: 'material',
  },
  table_view: {
    name: 'table_view',
    type: 'material',
  },
  cloud_upload: {
    name: 'cloud_upload',
    type: 'material',
  },
  upload_file: {
    name: 'upload_file',
    type: 'material',
  },
  fact_check: {
    name: 'fact_check',
    type: 'material',
  },
  arrow_drop_down: {
    name: 'arrow_drop_down',
    type: 'material',
  },
  arrow_drop_up: {
    name: 'arrow_drop_up',
    type: 'material',
  },
  people: {
    name: 'people',
    type: 'material',
  },
  people_alt: {
    name: 'people_alt',
    type: 'material',
  },
  person_add: {
    name: 'person_add',
    type: 'material',
  },
  create: {
    name: 'create',
    type: 'material',
  },
  expand_less: {
    name: 'expand_less',
    type: 'material',
  },
  expand_more: {
    name: 'expand_more',
    type: 'material',
  },
  swap_vertical_circle: {
    name: 'swap_vertical_circle',
    type: 'material',
  },
  settings: {
    name: 'settings',
    type: 'material',
  },
  storage: {
    name: 'storage',
    type: 'material',
  },
  add_chart: {
    name: 'add_chart',
    type: 'material',
  },
  assignment: {
    name: 'assignment',
    type: 'material',
  },
  person: {
    name: 'person',
    type: 'material',
  },
  book: {
    name: 'menu_book',
    type: 'material',
  },
  view: {
    name: 'visibility',
    type: 'material',
  },
  folder: {
    name: 'folder',
    type: 'material',
  },
  folder_open: {
    name: 'folder_open',
    type: 'material',
  },
  auto_stories: {
    name: 'auto_stories',
    type: 'material',
  },
  freeze: {
    name: 'ac_unit',
    type: 'material',
  },
  send: {
    name: 'send',
    type: 'material',
  },
  cancel_schedule_send: {
    name: 'cancel_schedule_send',
    type: 'material',
  },
  schedule: {
    name: 'schedule',
    type: 'material',
  },
  restart_alt: {
    name: 'restart_alt',
    type: 'material',
  },
  restore: {
    name: 'settings_backup_restore',
    type: 'material',
  },
  wysiwyg: {
    name: 'wysiwyg',
    type: 'material',
  },
  power_settings_new: {
    name: 'power_settings_new',
    type: 'material',
  },
  flash_off: {
    name: 'flash_off',
    type: 'material',
  },
  admin_panel_settings: {
    name: 'admin_panel_settings',
    type: 'material',
  },
  add_moderator: {
    name: 'add_moderator',
    type: 'material',
  },
  business_center: {
    name: 'business_center',
    type: 'material',
  },
  request_quote: {
    name: 'request_quote',
    type: 'material',
  },
  pie_chart: {
    name: 'pie_chart',
    type: 'material',
  },
  format_bold: {
    name: 'format_bold',
    type: 'material',
  },
  receipt_long: {
    name: 'receipt_long',
    type: 'material',
  },
  supervised_user_circle: {
    name: 'supervised_user_circle',
    type: 'material',
  },
  navigate_before: {
    name: 'navigate_before',
    type: 'material',
  },
  navigate_next: {
    name: 'navigate_next',
    type: 'material',
  },
  done: {
    name: 'done',
    type: 'material',
  },
  done_all: {
    name: 'done_all',
    type: 'material',
  },
  manage_search: {
    name: 'manage_search',
    type: 'material',
  },
  monitoring: {
    name: 'monitoring',
    type: 'material',
  },
  monitoring_dashboard: {
    name: 'swap_driving_apps_wheel',
    type: 'material',
  },
  settings_overscan: {
    name: 'settings_overscan',
    type: 'material',
  },
  download: {
    name: 'file_download',
    type: 'material',
  },
  dataset: {
    name: 'dataset',
    type: 'material',
  },
  expand: {
    name: 'expand',
    type: 'material',
  },
  compress: {
    name: 'compress',
    type: 'material',
  },
  sync: {
    name: 'sync',
    type: 'material',
  },
  rule: {
    name: 'rule',
    type: 'material',
  },
  gpp_bad: {
    name: 'gpp_bad',
    type: 'material',
  },
  stacked_bar_chart: {
    name: 'stacked_bar_chart',
    type: 'material',
  },
  file_open: {
    name: 'file_open',
    type: 'material',
  },
  pageview: {
    name: 'pageview',
    type: 'material',
  },
  settings_backup_restore: {
    name: 'settings_backup_restore',
    type: 'material',
  },
  preview: {
    name: 'preview',
    type: 'material',
  },
  org_tree: {
    name: 'account_tree',
    type: 'material',
  },
  movie: {
    name: 'movie',
    type: 'material',
  },
  pdf: {
    name: 'picture_as_pdf',
    type: 'material',
  },
  data_object: {
    name: 'data_object',
    type: 'material',
  },
  waving_hand: {
    name: 'waving_hand',
    type: 'material',
  },
  read_more: {
    name: 'read_more',
    type: 'material',
  },
  bulk_edit: {
    name: 'edit_note',
    type: 'material',
  },
  manage_accounts: {
    name: 'manage_accounts',
    type: 'material',
  },
  monitoring_cached: {
    name: 'timeline',
    type: 'material',
  },
  monitoring_realtime: {
    name: 'show_chart',
    type: 'material',
  },
  account_circle: {
    name: 'account_circle',
    type: 'material',
  },
  description: {
    name: 'description',
    type: 'material',
  },
  close: {
    name: 'close',
    type: 'material',
  },
  keyboard_arrow_left: {
    name: 'keyboard_arrow_left',
    type: 'material',
  },
  keyboard_arrow_right: {
    name: 'keyboard_arrow_right',
    type: 'material',
  },
  monetization_on: {
    name: 'monetization_on',
    type: 'material',
  },
  filter_alt: {
    name: 'filter_alt',
    type: 'material',
  },
  view_column: {
    name: 'view_column',
    type: 'material',
  },
  hub: {
    name: 'hub',
    type: 'material',
  },
  business_icon: {
    name: 'business',
    type: 'material',
  },
  contacts: {
    name: 'contacts',
    type: 'material',
  },
  card_travel: {
    name: 'card_travel',
    type: 'material',
  },
  legend_toggle: {
    name: 'legend_toggle',
    type: 'material',
  },
  file_add: {
    name: 'note_add',
    type: 'material',
  },
  file: {
    name: 'note',
    type: 'material',
  },
  file_del: {
    name: 'scan_delete',
    type: 'material',
  },
  attach_file: {
    name: 'attach_file',
    type: 'material',
  },
  attach_file_add: {
    name: 'attach_file_add',
    type: 'material',
  },
  carbon_copy: {
    name: 'stacked_email',
    type: 'material'
  },
  text_fields: {
    name: 'text_fields',
    type: 'material'
  },
  contact_mail: {
    name: 'contact_mail',
    type: 'material'
  },
  mail: {
    name: 'mail',
    type: 'material'
  },
  library_books: {
    name: 'library_books',
    type: 'material'
  },
  communication: {
    name: 'headset_mic',
    type: 'material'
  },
  notifications: {
    name: 'notifications',
    type: 'material'
  },
  lock: {
    name: 'lock',
    type: 'material'
  },
  lock_open: {
    name: 'lock_open',
    type: 'material'
  },
  buy: {
    name: 'shopping_cart',
    type: 'material'
  },
  sell: {
    name: 'sell',
    type: 'material'
  },
  view_compact_alt: {
    name: 'view_compact_alt',
    type: 'material'
  },
  external_link: {
    name: 'language',
    type: 'material'
  },
  image: {
    name: 'image',
    type: 'material'
  },
  suitability_review: {
    name: 'show_chart',
    type: 'material',
  },
  link: {
    name: 'link',
    type: 'material',
  },
  copy: {
    name: 'content_copy',
    type: 'material'
  },
};
