import {Component, EventEmitter, Input, OnInit, Output, signal} from "@angular/core";
import {
  getReportEntityType,
  ReportEntityBody,
  ReportFilter,
  ReportSchema,
  ReportSchemaEntity,
  ReportSchemaFilter,
  ReportSchemaSort
} from "./report-utils";
import {ExportRequestBody} from "../../../../../api/core";
import {DialogWidth, ModalService} from "../../../../../services/modal.service";
import {ModalData} from "../../../../../models/modal.model";
import {EModalType} from "../../../../../util/enum";
import {TranslateService} from "@ngx-translate/core";
import {ReportFilterComponent} from "../report-filter/report-filter.component";
import {FormBuilder} from "@angular/forms";

type Option = {
  text: string;
  value: string;
}
type SelectedOption = Option & {
  selected: boolean;
  tooltip?: string;
}

@Component({
  selector: 'app-report-body',
  templateUrl: 'report-body.component.html'
})
export class ReportBodyComponent implements OnInit {
  @Input() key: string = "";
  @Input() entity: string;
  @Input() originalSchema: ReportSchema;
  @Input()
  get schema() {
    return this._schema;
  }
  set schema(value: ReportSchemaEntity) {
    this._schema = value;
    this.fields = Object.entries(value.fields || {}).map(([key, val]) => ({
      text: key,
      value: key,
      selected: true,
      tooltip: val,
    }));
    this.entities = Object.entries(value.entities || {})
      .map(([key, val]) => ({
        value: getReportEntityType(val),
        text: key,
        selected: false,
      }));
    this.filters = this.fields.map(f => ({...f, selected: false}));
    this.sortBy = this.fields.map(f => ({...f, selected: false}));
    this.updateAddEntities();
    this.updateAddFilters();
    this.updateAddSortBy();
  }
  @Input()
  get body() {
    return this._body;
  }
  set body(value: ExportRequestBody) {
    this._body = value;
    value.fields = value.fields || [];
    value.entities = value.entities || {};
    value.filters = value.filters || {};
    value.sortBy = value.sortBy || [];
    if (value.fields.length === 0) value.fields = this.fields.map(f => f.value);

    this.updateAddEntities();
    this.updateAddFilters();
    this.updateAddSortBy();
  }
  @Output() onRemove = new EventEmitter<string>();

  private _schema: ReportSchemaEntity;
  private _body: ExportRequestBody;

  readonly openState = signal(false);
  readonly fieldsOpenState = signal(false);
  readonly entitiesOpenState = signal(false);
  readonly filtersOpenState = signal(false);
  readonly sortByOpenState = signal(false);
  readonly limitOpenState = signal(false);

  fields: SelectedOption[] = [];
  entities: SelectedOption[] = [];
  filters: SelectedOption[] = [];
  sortBy: SelectedOption[] = [];
  addEntities: string[] = [];
  addFilters: string[] = [];
  addSorts: string[] = [];
  bodyEntities: ReportEntityBody[] = [];
  bodyFilters: ReportFilter[] = [];
  bodySortBy: ReportSchemaSort[] = [];
  form = this.fb.group({
    limit: [''],
  });

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(changes => {
      this._body.limit = changes.limit ? +changes.limit : null;
    });
  }

  onFieldSelected(field: string, selected: boolean) {
    if (selected)
      this.body.fields = Array.from(new Set([...this.body.fields, field]));
    else
      this.body.fields = this.body.fields.filter(f => f !== field);
  }
  addEntity(entityName: string) {
    const childEntityType = getReportEntityType(this.schema.entities[entityName] || '');
    const entitySchema = this.originalSchema.entities[childEntityType];
    if (!entitySchema) return;
    const newEntity: ReportEntityBody = {
      key: entityName,
      name: childEntityType,
      spec: entitySchema,
      body: {}
    };
    this.bodyEntities = [...this.bodyEntities, newEntity];
    this.body.entities[entityName] = newEntity.body;
    this.entities.filter(f => f.text === entityName).forEach(e => e.selected = true);
    this.updateAddEntities();
  }

  removeEntity(entityName: string) {
    const base = this._body;
    this.bodyEntities = this.bodyEntities.filter(be => be.key !== entityName);
    delete base.entities[entityName];
    this.entities.filter(f => f.text === entityName).forEach(e => e.selected = false);
    this.updateAddEntities();
  }

  addFilter(fieldName: string) {
    if (!this.fields.find(f => f.value === fieldName)) return;
    const filter: ReportSchemaFilter = {
      filter: 'eq',
    };
    this._body.filters[fieldName] = filter;
    this.bodyFilters = [...this.bodyFilters, {
      field: fieldName,
      value: fieldName,
      filter
    }];
    this.filters.filter(f => f.value === fieldName).forEach(f => f.selected = true);
    this.updateAddFilters();
    this.editFilter(fieldName);
  }
  editFilter(fieldName: string) {
    const filter = this._body.filters[fieldName];
    const modalData: ModalData = {
      type: EModalType.bucketAdd,
      title: 'Edit Filter',
      submitBtn: {
        label: this.translateService.instant('save'),
      },
      data: {
        filter: {
          field: fieldName,
          filter
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: ReportFilterComponent,
    };
    const ref = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.AUTO
    );
    ref.afterClosed().subscribe((result: ReportFilter | null) => {
      if (!result) return;
      this._body.filters[fieldName] = result.filter;
      this.bodyFilters = this.bodyFilters.map(bf => bf.field === fieldName ? result : bf);
    });
  }
  removeFilter(fieldName: string) {
    delete this._body.filters[fieldName];
    this.bodyFilters = this.bodyFilters.filter(bf => bf.field !== fieldName);
    this.filters.filter(f => f.value === fieldName).forEach(f => f.selected = false);
    this.updateAddFilters();
  }

  addSortBy(fieldName: string) {
    if (!this.fields.find(f => f.value === fieldName)) return;
    const sortBy: ReportSchemaSort = {
      field: fieldName,
    };
    this._body.sortBy = [...this._body.sortBy, sortBy];
    this.bodySortBy = [...this.bodySortBy, sortBy];
    this.sortBy.filter(f => f.value === fieldName).forEach(f => f.selected = true);
    this.updateAddSortBy();
  }
  editSortBy(fieldName: string) {
    const sortBy = this._body.sortBy.find(s => s.field === fieldName);
    if (!sortBy) return;
    sortBy.desc = !sortBy.desc;
    this.bodySortBy = [...this.bodySortBy];
  }
  removeSortBy(fieldName: string) {
    this._body.sortBy = this._body.sortBy.filter(s => s.field !== fieldName);
    this.bodySortBy = this.bodySortBy.filter(s => s.field !== fieldName);
    this.sortBy.filter(f => f.value === fieldName).forEach(f => f.selected = false);
    this.updateAddFilters();
  }

  private updateAddEntities() {
    this.addEntities = this.entities.filter(e => !e.selected).map(e => e.text);
  }

  private updateAddFilters() {
    this.addFilters = this.filters.filter(f => !f.selected).map(f => f.text);
  }

  private updateAddSortBy() {
    this.addSorts = this.sortBy.filter(s => !s.selected).map(s => s.text);
  }
}
