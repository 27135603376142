import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  CampaignStatus,
  MonitoringBucket,
  MonitoringCampaign,
  MonitoringCharts,
  MonitoringService
} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {ECodeTables, EViewRoutes} from 'src/app/util/enum';
import {GridComponent} from 'src/app/shared/grid/grid.component';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDoubleClickedEvent,
  ValueFormatterParams
} from 'ag-grid-community';
import {CodeTableService} from 'src/app/services/code-table.service';
import {
  genCodeTableColumn,
  genDateColumn,
  genEnumColumn,
  genNumberColumn,
  genPercentageNumberColumn,
  genTextColumn,
} from 'src/app/util/grid/grid-renderer.util';
import {Observable, Subscription} from 'rxjs';
import {I18n} from '../../../services/i18n.service';
import {MonitoringUpdateProgressSocket} from "../../../util/monitoring-update-progress-socket";
import {OAuthService} from "angular-oauth2-oidc";
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {MonitoringChartComponent} from "../../../shared/monitoring-chart/monitoring-chart.component";
import {ElemSize, HtmlService} from "../../../services/html.service";
import {LabelBuilder} from "../../../util/label-builder";

type DataType = "top" | "best" | "all";
interface ChartSizes {
  chartBodyWidth: number;
  chartBodyHeight: number;
  totalWidth: number;
  totalHeight: number;
  chartContainerHeight: number;
  chartContainerMaxHeight: number,
  listContainerHeight: number;
  cardHeaderHeight: number;
  chartRowHeight: number[];
}

interface ChartElemSizes {
  chartLang: ElemSize;
  chartChannel: ElemSize;
  chartCentralized: ElemSize;
  chartTrades: ElemSize;
  chartParticipation: ElemSize;
  chartContacts: ElemSize;
  chartView: ElemSize;
  chartExec: ElemSize;
}

@Component({
  selector: 'app-monitoring-campaign',
  templateUrl: './monitoring-campaign.component.html',
})
export class MonitoringCampaignComponent implements OnInit, OnDestroy {
  @ViewChild('grid_all') grid: GridComponent;
  @ViewChild('grid_best') gridBest: GridComponent;
  @ViewChild('grid_top') gridTop: GridComponent;
  @ViewChild('chart_lang') chartLang: MonitoringChartComponent;
  @ViewChild('chart_channel') chartChannel: MonitoringChartComponent;
  @ViewChild('chart_trades') chartTrades: MonitoringChartComponent;
  @ViewChild('chart_participation') chartParticipation: MonitoringChartComponent;
  @ViewChild('chart_contacts') chartContacts: MonitoringChartComponent;
  @ViewChild('chart_view') chartView: MonitoringChartComponent;
  @ViewChild('chart_exec') chartExec: MonitoringChartComponent;
  @ViewChild('chart_centralized') chartCentralized: MonitoringChartComponent;
  @Input() refreshEvent: Observable<string>;
  @Input() selectedBucketSubject: Observable<MonitoringBucket>;

  columnDefs: ColDef[] = [
    genLinkColumn({
      field: 'name',
      headerName: I18n.getColName('name'),
      link: (data: any) => `${EViewRoutes.monitoringCampaignOverview}${data.id}`,
    }),
    {
      ...genTextColumn(
        'storyName',
        I18n.getColName('storyName'),
        null,
      ),
      hide: true,
    },
    genDateColumn({
      field: 'validFrom',
      headerName: I18n.getColName('validFrom'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validFrom),
    }),
    genDateColumn({
      field: 'validTo',
      headerName: I18n.getColName('validTo'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validTo),
    }),
    {
      ...genCodeTableColumn({
        field: 'useCase',
        headerName: I18n.getColName('useCase'),
        observable: this.codeTableService.getCodeTable(ECodeTables.useCase),
      }),
      hide: true,
    },
    {
      ...genEnumColumn({
        field: 'status',
        values: [
          CampaignStatus.LAUNCHED,
          CampaignStatus.FROZEN,
          CampaignStatus.CLOSED,
          CampaignStatus.DRAFT,
          CampaignStatus.TERMINATED,
        ],
        headerName: I18n.getColName('status'),
        filterParamsInfo: {customPath: 'status'},
      }),
      hide: true,
    },
    {
      ...genNumberColumn(
        'portfolioCount',
        I18n.getColName('portfolios'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true,
    },
    {
      ...genPercentageNumberColumn(
        'clientContactsPercentage',
        I18n.getColName('contacted'),
        this.globalService
      ),
    },
    {
      ...genNumberColumn(
        'clientContacts',
        I18n.getColName('contacts'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true
    },
    {
      ...genPercentageNumberColumn(
        'clientViewedPercentage',
        I18n.getColName('viewedRate'),
        this.globalService
      ),
    },
    {
      ...genNumberColumn(
        'clientViewed',
        I18n.getColName('viewed'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true
    },
    {
      ...genPercentageNumberColumn(
        'executionsPercentage',
        I18n.getColName('executedRate'),
        this.globalService
      ),
    },
    {
      ...genNumberColumn(
        'executions',
        I18n.getColName('executions'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0),
      ),
      hide: true
    },
    {
      ...genPercentageNumberColumn(
        'noActionsPercentage',
        I18n.getColName('noActionsRate'),
        this.globalService
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'noActions',
        I18n.getColName('noActions'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true,
    },
    {
      ...genPercentageNumberColumn(
        'pendingActionsPercentage',
        I18n.getColName('pendingActionsRate'),
        this.globalService
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'pendingActions',
        I18n.getColName('pendingActions'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true
    },
    {
      ...genNumberColumn(
        'participationCount',
        I18n.getColName('participationCount'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true
    },
    {
      ...genPercentageNumberColumn(
        'participationPercentage',
        I18n.getColName('participationPercentage'),
        this.globalService
      ),
    },
    {
      ...genNumberColumn(
        'sellVolume',
        this.labelBuilder.labelWithCurrency('sellTradingVolume'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 2)
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'buyVolume',
        this.labelBuilder.labelWithCurrency('buyTradingVolume'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 2)
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'totalVolume',
        this.labelBuilder.labelWithCurrency('totalTradingVolume'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 2)
      ),
    },
    {
      ...genNumberColumn(
        'clientCount',
        I18n.getColName('clients'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0),
      ),
      hide: true,
    },
    {
      ...genDateColumn({
        field: 'launchedAt',
        headerName: I18n.getColName('launchedAt'),
        dateFormatter: (params: ValueFormatterParams) =>
          this.globalService.dateToFrChLocale(params.data.launchedAt),
      }),
      hide: true,
    },
    {
      ...genDateColumn({
        field: 'createdAt',
        headerName: I18n.getColName('monitoringCachedAt'),
        dateFormatter: (params: ValueFormatterParams) =>
          this.globalService.dateToFrChLocale(params.data.createdAt),
      }),
      hide: true,
    },
    {
      ...genCodeTableColumn({
        field: 'publicationType',
        headerName: I18n.getColName('publicationType'),
        observable: this.codeTableService.getCodeTable(ECodeTables.publicationType),
      }),
      hide: true
    },
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    columnDefs: this.columnDefs,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.monitoringCampaignOverview}${event.data.id}`
      ),
    onGridReady: (event: GridReadyEvent) => {
      this.gridApi = event.api;
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
      this.dataType = this._dataType;
    }
  };
  gridOptionsBest: GridOptions = {
    rowHeight: 36,
    columnDefs: this.columnDefs,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.monitoringCampaignOverview}${event.data.id}`
      ),
    onGridReady: (event: GridReadyEvent) => {
      this.gridApiBest = event.api;
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
      this.dataType = this._dataType;
    }
  };
  gridOptionsTop: GridOptions = {
    rowHeight: 36,
    columnDefs: this.columnDefs,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.monitoringCampaignOverview}${event.data.id}`
      ),
    onGridReady: (event: GridReadyEvent) => {
      this.gridApiTop = event.api;
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
      this.dataType = this._dataType;
    }
  };

  selectedBucket: MonitoringBucket;
  rowData: MonitoringCampaign[] = [];
  _dataType: DataType = 'top';
  needsToUpdateCharts = false;
  chartSizes: ChartSizes;
  chartElemSizes: ChartElemSizes;

  subscriptions: Subscription[] = [];
  gridApi: GridApi;
  gridApiBest: GridApi;
  gridApiTop: GridApi;
  updating = false;
  _isCollapsing = false;
  pagination = true;
  charts = {
    contactsRate: 0,
    contactsAvg: 0,
    viewRate: 0,
    viewedAvg: 0,
    participationRate: 0,
    participationAvg: 0,
    executionRate: 0,
    executionAvg: 0,
    tradesVolume: {
      totalVolume: 0,
      totalTrades: 0,
      buyVolume: 0,
      sellVolume: 0,
      buyTrades: 0,
      sellTrades: 0
    },
    centralizedCampaigns: {
      total: 0,
      message: ''
    }
  }

  topAmount = 5;

  constructor(
    protected translateService: TranslateService,
    protected monitoringService: MonitoringService,
    protected codeTableService: CodeTableService,
    protected globalService: GlobalService,
    protected readonly htmlService: HtmlService,
    private readonly oauthService: OAuthService,
    protected labelBuilder: LabelBuilder,
  ) {
    this.getSocket = this.getSocket.bind(this);
  }

  ngOnInit(): void {
    this.monitoringService.checkMonitoring()
      .subscribe(value => {
        this.updating = value.calculating;
      });
    this.dataType = 'top';
    if (this.refreshEvent) {
      this.subscriptions.push(this.refreshEvent.subscribe(this.onRefresh.bind(this)));
    }
    if (this.selectedBucketSubject) {
      this.subscriptions.push(
        this.selectedBucketSubject.subscribe(bucket => {
          this.onSelectedBucket(bucket);
        })
      );
    }
    // a delay is required since the component needs to be rendered first
    setTimeout(() => this.initSwiper());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get isCollapsing(): boolean {
    return this._isCollapsing;
  }

  set isCollapsing(value: boolean) {
    this._isCollapsing = value;

    if (value && this.gridTop && this.dataType === 'top') {
      // necessary that top grid is rendered the same as the other grids when empty
      setTimeout(() => this.gridTop.gridApi.sizeColumnsToFit());
    }
    if (!value && this.needsToUpdateCharts) {
      this.needsToUpdateCharts = false;
      this.updateCharts();
    }
  }

  private onRefresh(event: string) {
    if (event === 'refreshData') {
      this.handleUpdate();
    }
  }

  get dataType() {
    return this._dataType;
  }

  set dataType(value: DataType) {
    this._dataType = value;
    switch (value) {
      case 'top':
        this.updateTop();
        break;
      case 'best':
        this.updateBest();
        break;
      case 'all':
        this.updateAll();
        break;
    }
  }

  private updateTop() {
    this.htmlService.waitFor(
      () => !!this.gridTop?.gridApi && !!this.selectedBucket,
      () => {
        this.monitoringService.getCampaignsTop(
          this.selectedBucket?.id ?? 0,
          this.topAmount
        ).subscribe(page => {
          this.rowData = page.pageItems;
          // necessary that top grid is rendered the same as the other grids when empty
          setTimeout(() => this.gridTop.gridApi.sizeColumnsToFit());
        });
        this.gridTop.gridApi.applyColumnState({
          state: this.gridTop.gridApi.getColumnState().map(cs => {
            cs.sort = (cs.colId === 'participationPercentage') ? 'desc' : null;
            return cs;
          }),
        });
      })
  }

  private updateBest() {
    this.htmlService.waitFor(
      () => !!this.gridBest && !!this.selectedBucket,
      () => {
        this.gridBest.updateDataSource(this.monitoringService.getCampaignsBest.bind(
          this.monitoringService,
          this.selectedBucket?.id ?? 0
        ));
        this.htmlService.waitFor(
          () => !!this.gridBest.gridApi,
          () => {
            this.gridBest.dataSource.initDatasource(this.gridBest.gridApi);
            this.gridBest.gridApi.applyColumnState({
              state: this.gridBest.gridApi.getColumnState().map(cs => {
                cs.sort = (cs.colId === 'participationPercentage') ? 'desc' : null;
                return cs;
              }),
            });
          }
        );
      }
    );
  }

  private updateAll() {
    this.htmlService.waitFor(
      () => !!this.grid && !!this.selectedBucket,
      () => {
        this.grid.updateDataSource(this.monitoringService.getCampaigns.bind(
          this.monitoringService,
          this.selectedBucket?.id ?? 0
        ));
        this.htmlService.waitFor(
          () => !!this.grid.gridApi,
          () => {
            this.grid.dataSource.initDatasource(this.grid.gridApi);
            this.grid.gridApi.applyColumnState({
              state: this.grid.gridApi.getColumnState().map(cs => {
                cs.sort = (cs.colId === 'launchedAt') ? 'desc' : null;
                return cs;
              }),
            });
          }
        )
      }
    );
  }

  gridRefresh = () => {
    if (this.updating) {
      return;
    }
    switch (this.dataType) {
      case 'all':
        this.grid.refresh(true);
        break;
      case 'best':
        this.gridBest.refresh(true);
        break;
    }
  }

  handleUpdate() {
    this.monitoringService.updateMonitoring()
      .subscribe(_ => {
        this.updating = true;
      })
  }

  getSocket() {
    return new MonitoringUpdateProgressSocket(
      this.oauthService.getAccessToken()
    );
  }

  onUpdateCompleted() {
    this.updating = false;
    this.updateCharts();
  }

  private async updateElementSizes() {
    const appMonitoringCampaign = this.htmlService.getElem('app-monitoring-campaign');
    const catalogContainer = this.htmlService.getElem('.catalogue-container', appMonitoringCampaign) as HTMLElement;
    const chartContainer = this.htmlService.getElem('.chart-container', appMonitoringCampaign) as HTMLElement;
    const listContainer = this.htmlService.getElem('.list-container', appMonitoringCampaign);
    const gap = this.htmlService.rem2pixel(1);
    const cardHeader = this.htmlService.getElem('.card-header', appMonitoringCampaign);
    const chartBody = this.htmlService.getElem('.chart-body', appMonitoringCampaign);
    const chartRows = this.htmlService.getElems('.chart-row', appMonitoringCampaign);
    if (!this.chartSizes) {
      const chartContainerHeight = chartContainer.clientHeight;
      const cardHeaderHeight = cardHeader.clientHeight;
      const totalHeight = appMonitoringCampaign.parentElement.clientHeight;
      const listContainerHeight = listContainer.clientHeight;
      this.chartSizes = {
        totalWidth: appMonitoringCampaign.parentElement.clientWidth,
        totalHeight: appMonitoringCampaign.parentElement.clientHeight,
        chartContainerHeight,
        listContainerHeight: listContainer.clientHeight,
        cardHeaderHeight,
        chartContainerMaxHeight: totalHeight - listContainerHeight - 2 * gap,
        chartBodyWidth: chartBody.clientWidth,
        chartBodyHeight: chartContainerHeight - cardHeaderHeight - gap,
        chartRowHeight: chartRows.map(r => r.clientHeight),
      };
    }
    this.htmlService.addStyle(catalogContainer, 'max-height', this.htmlService.getValue(this.chartSizes.totalHeight));
    this.htmlService.addStyle(chartContainer, 'max-height', this.htmlService.getValue(this.chartSizes.chartContainerMaxHeight));
    this.htmlService.addStyle(chartBody, 'max-height', this.htmlService.getValue(this.chartSizes.chartBodyHeight));
    chartRows.forEach((el, idx) => {
      this.htmlService.addStyle(el, 'max-width', this.htmlService.getValue(this.chartSizes.chartBodyWidth));
      this.htmlService.addStyle(el, 'max-height', this.htmlService.getValue(this.chartSizes.chartRowHeight[idx]));
    });
  }

  async updateCharts() {
    if (this.isCollapsing) {
      this.needsToUpdateCharts = true;
      return;
    }
    await this.updateElementSizes();
    this.monitoringService.getCampaignCharts(this.selectedBucket?.id ?? 0)
      .subscribe(charts => {
        const tradesVolume = this.charts.tradesVolume;
        const buyVolume = charts.buyVolume.reduce((prev, curr) => prev + curr.value, 0);
        const sellVolume = charts.sellVolume.reduce((prev, curr) => prev + curr.value, 0);
        const buyTrades = charts.buyTrades.reduce((prev, curr) => prev + curr.count, 0);
        const sellTrades = charts.sellTrades.reduce((prev, curr) => prev + curr.count, 0);
        tradesVolume.totalVolume = buyVolume + sellVolume;
        tradesVolume.totalTrades = buyTrades + sellTrades;
        tradesVolume.buyVolume = buyVolume;
        tradesVolume.buyTrades = buyTrades;
        tradesVolume.sellVolume = sellVolume;
        tradesVolume.sellTrades = sellTrades;
        this.charts.participationRate = charts.participationRate.value;
        this.charts.participationAvg = charts.participationRate.average;
        this.charts.contactsRate = charts.contactsRate.value;
        this.charts.contactsAvg = charts.contactsRate.average;
        this.charts.viewRate = charts.viewRate.value;
        this.charts.viewedAvg = charts.viewRate.average;
        this.charts.executionRate = charts.executionRate.value;
        this.charts.executionAvg = charts.executionRate.average;
        this.charts.centralizedCampaigns.total = charts.centralizedCampaignTotal;
        const centralizedMessage = this.translateService.instant((charts.centralizedCampaignAverage === 1) ? 'mChartCampaignAveragePerMonth' : 'mChartCampaignsAveragePerMonth');
        this.charts.centralizedCampaigns.message = centralizedMessage.replace(/[#]/, charts.centralizedCampaignAverage.toFixed(0));

        setTimeout(() => {
          this.updateChartGraphs(charts)
        }, 0);
      });
  }

  private updateChartGraphs(charts: MonitoringCharts) {
    const chartLang = this.htmlService.getElem('.chart-graph.lang .card-body');
    const chartChannel = this.htmlService.getElem('.chart-graph.channel .card-body');
    const chartCentralized = this.htmlService.getElem('.chart-graph.centralized-campaigns app-monitoring-chart');
    const chartTrades = this.htmlService.getElem('.chart-graph.trade-volume app-monitoring-chart');
    const chartParticipation = this.htmlService.getElem('.chart-graph.participation app-monitoring-chart') as HTMLElement;
    const chartContacts = this.htmlService.getElem('.chart-graph.contacts app-monitoring-chart') as HTMLElement;
    const chartView = this.htmlService.getElem('.chart-graph.view app-monitoring-chart') as HTMLElement;
    const chartExec = this.htmlService.getElem('.chart-graph.execution app-monitoring-chart') as HTMLElement;
    if (!this.chartElemSizes) {
      this.chartElemSizes = {
        chartLang: this.htmlService.getElemSize(chartLang),
        chartChannel: this.htmlService.getElemSize(chartChannel),
        chartCentralized: this.htmlService.getElemSize(chartCentralized),
        chartTrades: this.htmlService.getElemSize(chartTrades),
        chartParticipation: this.htmlService.getElemSize(chartParticipation),
        chartContacts: this.htmlService.getElemSize(chartContacts),
        chartView: this.htmlService.getElemSize(chartView),
        chartExec: this.htmlService.getElemSize(chartExec),
      }
    }
    const sizes = this.chartElemSizes;
    this.chartLang.setPieData('', charts.languages, {
      width: sizes.chartLang.width - 50,
      height: sizes.chartLang.height - 50
    });
    this.chartChannel.setPieData('', charts.channels, {
      width: sizes.chartChannel.width - 50,
      height: sizes.chartChannel.height - 50
    });
    this.chartCentralized.setCentralizedCampaigns(
      charts.centralizedCampaignCategories,
      charts.centralizedCampaignData,
      {
        width: sizes.chartCentralized.width,
        height: sizes.chartCentralized.height
      }
    );
    this.chartTrades.setTradeVolumeData('', charts, {
      width: sizes.chartTrades.width,
      height: sizes.chartTrades.height + 20,
      tooltipFormatter: p =>
        `${p.series.name}: ${this.globalService.getFormattedValue(p.y, 0)}
        ${p.series.index ? `${this.labelBuilder.selectedCurrencyLabel()}` : ''}`
    });
    const lineChartOffsetW = 2;
    const lineChartOffsetH = 3;
    this.chartParticipation.setLineChart(
      charts.dataPointSlices,
      charts.participationRate,
      'ParticipationRate',
      {
        width: sizes.chartParticipation.width + lineChartOffsetW,
        height: sizes.chartParticipation.height + lineChartOffsetH
      });
    this.chartContacts.setLineChart(
      charts.dataPointSlices,
      charts.contactsRate,
      'ContactsRate',
      {
        width: sizes.chartContacts.width + lineChartOffsetW,
        height: sizes.chartContacts.height + lineChartOffsetH
      });
    this.chartView.setLineChart(
      charts.dataPointSlices,
      charts.viewRate,
      'ViewRate',
      {
        width: sizes.chartView.width + lineChartOffsetW,
        height: sizes.chartView.height + lineChartOffsetH
      });
    this.chartExec.setLineChart(
      charts.dataPointSlices,
      charts.executionRate,
      'ExecutionRate',
      {
        width: sizes.chartExec.width + lineChartOffsetW,
        height: sizes.chartExec.height + lineChartOffsetH
      });
    setTimeout(() => {
      this.chartParticipation.updateLineChart(chartParticipation);
      this.chartContacts.updateLineChart(chartContacts);
      this.chartView.updateLineChart(chartView);
      this.chartExec.updateLineChart(chartExec);
    }, 0)
  }

  private initSwiper() {
    // swiper element
    const swiperEl: any = document.querySelector('swiper-container');
    if (swiperEl) {
      const swiperParams = {
        loop: true,
        pagination: {
          clickable: true,
        },
      };
      // assign all parameters to Swiper element
      Object.assign(swiperEl, swiperParams);
      // and now initialize it
      swiperEl.initialize();
    }
  }

  private onSelectedBucket(bucket: MonitoringBucket) {
    this.selectedBucket = bucket;
    this.dataType = this._dataType;
    this.updateCharts();
  }
}
