import {Component, OnInit} from "@angular/core";
import {ExportRequest, ReportService} from "../../../../../api/core";
import {FormBuilder, Validators} from "@angular/forms";
import {ReportSchema, ReportSchemaEntity} from "../report-body/report-utils";
import {NotificationService} from "../../../../../services/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {ModalService} from "../../../../../services/modal.service";

type Option = {
  text: string;
  value: string;
}

@Component({
  selector: 'app-report-request-creator',
  templateUrl: 'report-creator.component.html'
})
export class ReportCreatorComponent implements OnInit {

  schema: ReportSchema;
  masterEntity: string;
  masters: Option[];
  root: ExportRequest = {master: '', spec: {}};
  rootSchema: ReportSchemaEntity;
  formGroup = this.fb.group({
    master: ['', Validators.required],
    type: ['', Validators.required],
  })

  constructor(
    private fb: FormBuilder,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private modalService: ModalService,
  ) {

  }

  ngOnInit() {
    this.reportService.reportSchema().subscribe(schema => {
      this.schema = schema.schema as ReportSchema;
      this.prepareSchema();
    });
  }

  onSend() {
    const ident = new Date().toISOString();
    const dialogRef = this.modalService.openPromptText('Generate Report Request', 'ident', ident, null);
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      this.reportService.generateReport(result, this.root).subscribe(ok => {
        this.notificationService.handleSuccess("Report Request sent successfully");
      });
    })
  }

  onTypeChanged(type: any) {
    this.root.type = type;
  }

  onMasterChanged(master: string) {
    this.root.master = '';
    setTimeout(() => {
      this.masterEntity = this.masters.find(m => m.value == master).text;
      this.rootSchema = this.schema.entities[this.masterEntity];
      this.root = {
        master,
        spec: {},
        type: this.root.type
      };
    });
  }

  async onCopyRequest() {
    this.reportService.validateReport(this.root).subscribe(errors => {
      if (errors && errors.length > 0) {
        this.notificationService.handleError(`Request is not valid ${errors.join("\n,")}`);
      } else {
        const json = JSON.stringify(this.root, null, 2);
        navigator.clipboard.writeText(json).then(() => {
          this.notificationService.handleSuccess('Request JSON Copied to Clipboard');
        });
      }
    });
  }

  private prepareSchema() {
    const entityNames = Object.keys(this.schema.entities)
      .map(key => ({
        key,
        value: key.slice(0, 1).toLowerCase() + key.slice(1)
      }));
    this.masters = this.schema.masters.map(m => {
      return {
        value: m,
        text: entityNames.find(n => n.value == m).key
      }
    })
  }
}
