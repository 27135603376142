import {Component} from "@angular/core";
import {ColDef, GridApi, GridOptions, GridReadyEvent} from "ag-grid-community";
import {ReportService} from "../../../../api/core";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../../services/global.service";
import {GridDataProvider} from "../../../../shared/grid/data-source";
import {genDateColumn, genEnumColumn} from "../../../../util/grid/grid-renderer.util";

@Component({
  selector: 'app-report-status-list',
  templateUrl: './report-status-list.component.html'
})
export class ReportStatusListComponent {
  colDefs: ColDef[] = [
    {field: 'ident', headerName: this.translateService.instant('ident')},
    genDateColumn({
      field: 'createdAt',
      headerName: this.translateService.instant('creationDate')
    }),
    genDateColumn({
      field: 'repliedAt',
      headerName: this.translateService.instant('lastUpdatedAt')
    }),
    genEnumColumn({
      field: 'status',
      headerName: this.translateService.instant('status'),
      values: ['PROCESSING', 'DONE', 'ERROR']
    }),
    {field: 'description', headerName: this.translateService.instant('description')}
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent<any>) => (this.gridApi = event.api)
  };

  gridApi: GridApi;

  data: GridDataProvider = this.reportService.getReportStatusList.bind(this.reportService);

  constructor(
    private readonly translateService: TranslateService,
    private readonly globalService: GlobalService,
    private readonly reportService: ReportService,
  ) {
  }

  onRefresh() {
    this.gridApi?.refreshServerSide();
  }
}
