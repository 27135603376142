<app-grid-filter
  *ngIf="tableStateUtil && gridApi && gridFilterEnabled"
  [class.no-grid-filter-top-padding]="noGridFilterTopPadding"
  [class.no-grid-filter-padding]="noGridFilterPadding"
  [gridApi]="gridApi"
  [dialogTitle]="'filters' | translate"
  [filterModelSubject]="filterModelSubject"
  [showSearch]="showSearch"
  [searchPlaceholder]="searchPlaceholder"
  (searchChanged)="(searchValue = $event)"
  [importantColumns]="importantColumns"
></app-grid-filter>

<ag-grid-angular
  *ngIf="tableStateUtil && columnDefs && data"
  class="ag-theme-aspark"
  [class.bottom-bar-padding]="!pagination"
  [class.dialog-filter]="featureDialogFilters"
  [ngClass]="dialogFilterHeightAndPaddingClass"
  rowModelType="serverSide"
  [domLayout]="domLayout"
  [paginationPageSize]="paginationPageSizeNumber"
  [paginationAutoPageSize]="paginationAutoPageSize"
  [cacheBlockSize]="cacheBlockSize"
  [serverSideDatasource]="dataSource"
  [columnDefs]="columnDefs"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [groupRowRendererParams]="groupRowRendererParams"
  [groupDisplayType]="groupDisplayType"
  [gridOptions]="gridOptions"
  [rowMultiSelectWithClick]="rowMultiSelectWithClick"
  [rowSelection]="rowSelection"
  [pagination]="pagination"
  [getLocaleText]="getLocaleText"
  (gridReady)="onGridReady($event)"
  (rowGroupOpened)="onRowGroupOpened($event)"
  (modelUpdated)="onModelUpdated($event)"
  (filterChanged)="resetExpandedRows()"
  (sortChanged)="resetExpandedRows()"
  (paginationChanged)="onPaginationChanged($event)"
  [floatingFiltersHeight]="floatingFiltersHeight"
>
</ag-grid-angular>

<ag-grid-angular
  *ngIf="tableStateUtil && columnDefs && rowData"
  class="ag-theme-aspark"
  [class.bottom-bar-padding]="!pagination"
  [class.dialog-filter]="featureDialogFilters"
  [ngClass]="dialogFilterHeightAndPaddingClass"
  [paginationPageSize]="paginationPageSizeNumber"
  [paginationAutoPageSize]="paginationAutoPageSize"
  [columnDefs]="columnDefs"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [groupRowRendererParams]="groupRowRendererParams"
  [groupDisplayType]="groupDisplayType"
  [rowData]="rowData"
  [gridOptions]="gridOptions"
  [rowMultiSelectWithClick]="rowMultiSelectWithClick"
  [rowSelection]="rowSelection"
  [pagination]="pagination"
  [getLocaleText]="getLocaleText"
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="adjustPage($event)"
  (paginationChanged)="onPaginationChanged($event)"
  (filterChanged)="sortAndFilterListener($event)"
  (sortChanged)="sortAndFilterListener($event)"
  [floatingFiltersHeight]="floatingFiltersHeight"
>
</ag-grid-angular>
<!--Check if grid ready to avoid overlapping flickering icons at the top of the grid before grid is ready-->
<div class="grid-basic-toolbar neg-margin" *ngIf="!hideFooter && gridApi" [ngClass]="{'pagination': data && pagination}">
  <app-config-icon
    iconIdentifier="settings_overscan"
    [tooltip]="'autoSizeColWidths' | translate"
    (click)="resetColWidths()"
  ></app-config-icon>
  <app-config-icon
    iconIdentifier="settings_backup_restore"
    [tooltip]="'resetGrid' | translate"
    (click)="resetGrid()"
  >
  </app-config-icon>
  <app-config-icon
    #sidebar_columns_trigger
    *ngIf="sidebarEnabled"
    iconIdentifier="view_column"
    [tooltip]="'manageColumns' | translate"
    (click)="toggleColumnsMenu()"
  ></app-config-icon>
  <app-config-icon
    *ngIf="showRefreshButton"
    iconIdentifier="refresh"
    [tooltip]="'refreshGridData' | translate"
    (click)="refreshGridData()"
  ></app-config-icon>
  <div *ngIf="selectionCount > 0" class="selection-container">
    <div class="count-label">
    <span
      *ngIf="selectionCount === 1 && labelRefs.singular"
      class="label"
    >
      {{ selectionCount }} {{ labelRefs.singular | translate }}
    </span>
      <span *ngIf="selectionCount > 1 && labelRefs.plural">
      {{ selectionCount }} {{ labelRefs.plural | translate }}
    </span>
    </div>
    <div
      class="icon-text-btn error-btn"
      (click)="clearSelection()"
      [matTooltip]="'clearSelection' | translate"
    >
      <app-config-icon iconIdentifier="clear"></app-config-icon>
      <span class="body-2">{{ 'clear' | translate }}</span>
    </div>
  </div>
  <mat-select
    (selectionChange)="onPaginationChange()"
    [(ngModel)]="paginationPageSize"
    [matTooltip]="'changePaginationSize' | translate" *ngIf="columnDefs && data && pagination">
    <mat-option *ngFor="let option of paginationOptions" [value]="option.key">{{ option.label }}</mat-option>
  </mat-select>
</div>
