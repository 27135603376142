import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../../shared/modal/modal.component";
import {ReportFilter} from "../report-body/report-utils";
import {FormBuilder, Validators} from "@angular/forms";
import {ModalSubComponent} from "../../../../../models/modal.model";
import {EModalType} from "../../../../../util/enum";
import {ExportRequestField, ExportRequestFilterType} from "../../../../../api/core";

type Option = {
  value: string;
  text: string;
}

function opt(value: string, text: string): Option {
  return {value, text};
}

@Component({
  selector: 'app-report-filter',
  templateUrl: 'report-filter.component.html'
})
export class ReportFilterComponent implements ModalSubComponent {
  filter: ReportFilter;

  formGroup = this.fb.group({
    field: [''],
    operator: ['', Validators.required],
    type: ['str', Validators.required],
    value: [''],
  });
  operators: Option[] = [
    opt('eq', '='),
    opt('ne', '≠'),
    opt('in', 'in'),
    opt('nin', 'not in'),
    opt('like', 'like'),
    opt('lt', '<'),
    opt('gt', '>'),
    opt('gte', '≥'),
    opt('lte', '≤'),
  ];
  valueTypes = [
    opt('valueStr', 'string'),
    opt('valueInt', 'integer'),
    opt('valueNum', 'number'),
    opt('valueStrList', 'string list'),
    opt('valueIntList', 'integer list'),
  ];

  constructor(
    private fb: FormBuilder,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: {
        filter: ReportFilter
      };
    }
  ) {
    this.filter = data.data.filter;
    const type = this.getFilterValue(this.filter);
    this.formGroup.patchValue({
      field: this.filter.field,
      operator: this.filter.filter.filter,
      type: type.value,
      value: type.text,
    });
  }

  getFilterValue(f: ReportFilter): Option {
    const filter = f.filter;
    if (filter.valueStrList != null) return {
      text: filter.valueStrList.join(','),
      value: 'valueStrList'
    };
    if (filter.valueIntList != null) return {
      text: filter.valueIntList.join(','),
      value: 'valueIntList',
    } ;
    if (filter.valueInt != null) return {
      text: filter.valueInt.toString(),
      value: 'valueInt',
    };
    if (filter.valueNum != null) return {
      text: filter.valueNum.toString(),
      value: 'valueNum',
    };
    return {
      text: filter.valueStr || '',
      value: 'valueStr',
    };
  }

  modalAction(modalType?: EModalType) {
    try {
      const values = this.formGroup.getRawValue();
      const type = values.type;
      const value = values.value;
      const filter: ExportRequestField = {
        filter: values.operator as ExportRequestFilterType,
      }
      const rFilter: ReportFilter = {
        field: values.field,
        value,
        filter
      };
      switch (type) {
        case 'valueStr':
          filter.valueStr = value;
          break;
        case 'valueInt':
          filter.valueInt = Math.round(+value);
          break;
        case 'valueNum':
          filter.valueNum = +value;
          break;
        case 'valueStrList':
          filter.valueStrList = value.split(',').map(s => s.trim());
          rFilter.value = `(${filter.valueStrList.join(',')})`;
          break;
        case 'valueIntList':
          filter.valueIntList = value.split(',').map(s => Math.round(+s));
          rFilter.value = `(${filter.valueIntList.join(',')})`;
          break;
      }
      const operatorText = this.operators.find(o => o.value == filter.filter).text;
      rFilter.value = [rFilter.field, operatorText, rFilter.value].join(' ');
      this.dialogRef.close(rFilter);
    } catch (e) {
      console.error(e);
    }
  }
}
