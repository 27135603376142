<form [formGroup]="formGroup" class="modal-form">
  <div class="row">
    <mat-form-field>
      <mat-label>Field</mat-label>
      <input type="text" matInput formControlName="field" readonly />
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>Operator</mat-label>
      <mat-select formControlName="operator">
        <mat-option *ngFor="let o of operators" [value]="o.value">
          {{o.text}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let o of valueTypes" [value]="o.value">
          {{o.text}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>Value</mat-label>
      <input type="text" matInput formControlName="value" />
    </mat-form-field>
  </div>
</form>
