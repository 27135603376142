import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AdvisorsService } from './api/advisors.service';
import { AppMessageService } from './api/appMessage.service';
import { AssetService } from './api/asset.service';
import { BusinessService } from './api/business.service';
import { CampaignService } from './api/campaign.service';
import { CampaignActionService } from './api/campaignAction.service';
import { CampaignIntermediaryService } from './api/campaignIntermediary.service';
import { CampaignIntermediaryActionService } from './api/campaignIntermediaryAction.service';
import { CarbonCopyService } from './api/carbonCopy.service';
import { ChannelService } from './api/channel.service';
import { ClientService } from './api/client.service';
import { CodeTablesService } from './api/codeTables.service';
import { CollectionService } from './api/collection.service';
import { ContentService } from './api/content.service';
import { CurrencyService } from './api/currency.service';
import { DocumentService } from './api/document.service';
import { EmployeeService } from './api/employee.service';
import { EmployeeCollectionService } from './api/employeeCollection.service';
import { ExportService } from './api/export.service';
import { FeaturesService } from './api/features.service';
import { FilterService } from './api/filter.service';
import { GuiService } from './api/gui.service';
import { IdentService } from './api/ident.service';
import { InfoService } from './api/info.service';
import { IntermediaryService } from './api/intermediary.service';
import { LicenseService } from './api/license.service';
import { MonitoringService } from './api/monitoring.service';
import { OrgPositionService } from './api/orgPosition.service';
import { OutgoingMessageService } from './api/outgoingMessage.service';
import { PortfolioService } from './api/portfolio.service';
import { ProductService } from './api/product.service';
import { RelationshipManagerService } from './api/relationshipManager.service';
import { ReportService } from './api/report.service';
import { SalutationService } from './api/salutation.service';
import { SenderService } from './api/sender.service';
import { SentMessageService } from './api/sentMessage.service';
import { StoryService } from './api/story.service';
import { SystemStatusService } from './api/systemStatus.service';
import { TagService } from './api/tag.service';
import { TemplateDefaultService } from './api/templateDefault.service';
import { UserService } from './api/user.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
