import {Component, OnDestroy, OnInit} from '@angular/core';
import {genBooleanColumn, genCodeTableColumn, genTextColumn} from '../../../util/grid/grid-renderer.util';
import {I18n} from '../../../services/i18n.service';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../../services/global.service';
import {CodeTableEntry, LicenseService, User, UserRoleType, UserService} from '../../../api/core';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {GridResetEvent} from "../../../shared/grid/grid.component";
import {Tab} from "../../../models/tabs.model";
import {SETTINGS_TABS} from "../../../util/tab.constants";
import {CodeTableService} from "../../../services/code-table.service";
import {ECodeTables} from "../../../util/enum";

interface StatsEntry {
  label: string;
  value: string | number;
}
// UserWithRoleType extends User and adds a roleType property, meant to be used in the grid
interface UserWithRoleType extends User {
  roleType: CodeTableEntry;
}

const DEFAULT_TAB = 0;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit, OnDestroy {

  tabs: Tab[] = SETTINGS_TABS;
  defaultTab: string = this.tabs[DEFAULT_TAB].text;
  activeTab: string = this.tabs[DEFAULT_TAB].text;

  usersColumnDefs: ColDef[] = [
    genTextColumn(
      'username',
      I18n.getColName('username'),
    ),
    genTextColumn(
      'fullname',
      I18n.getColName('fullName'),
    ),
    genTextColumn(
      'email',
      I18n.getColName('email'),
    ),
    genTextColumn(
      'company',
      I18n.getColName('company'),
    ),
    genTextColumn(
      'department',
      I18n.getColName('department'),
    ),
    {
      ...genCodeTableColumn({
        field: 'roleType',
        observable: this.getUserRoleTypesAsCodeTable(),
        headerName: I18n.getColName('role'),
      }),
      floatingFilter: true,
      sortable: true,
    },
    {
      ...genBooleanColumn(
        'closed',
        this.translateService.instant('closed'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    {
      ...genBooleanColumn(
        'licensed',
        this.translateService.instant('licensed'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    {
      ...genTextColumn(
        'title',
        I18n.getColName('title'),
      ),
      hide: true,
    },
    {
      ...genTextColumn(
        'phoneNumber',
        I18n.getColName('phoneNumber'),
      ),
      hide: true,
    },
    {
      ...genTextColumn(
        'workplace.description',
        I18n.getColName('workplace'),
      ),
      hide: true,
    },
    {
      ...genCodeTableColumn({
        field: 'hubs',
        headerName: this.translateService.instant('hub'),
        observable: this.codeTableService.getCodeTable(ECodeTables.hub),
      }),
      valueFormatter: (params) =>
        [...new Set(params.value?.map((d) => d.name))].join(', '),
      hide: true,
    },
  ];

  usersGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent<User>) => {
      this.onGridReady(event);
    }
  };

  subscriptions: Subscription[] = [];
  statEntries: StatsEntry[] = [];
  users: UserWithRoleType[] = [];
  private userRoleTypesAsCodeTable: CodeTableEntry[] = [];

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly globalService: GlobalService,
    protected readonly userService: UserService,
    protected readonly licenseService: LicenseService,
    protected readonly codeTableService: CodeTableService,
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.userService.getAllUsers(),
      this.userService.getUserRoleCount(),
      this.licenseService.getLicenseCount(),
    ]).subscribe({
      next: ([users, roleCount, licenseCount]) => {
        this.users = users.map((user) => {
          return {...user, roleType: this.userRoleTypeToCodeTableEntry(user.role)}
        });
        this.statEntries = Object.entries(roleCount).map(([key, val]) => ({
          label: this.translateService.instant(`role_${key.toLowerCase()}`),
          value: val,
        }));
        this.statEntries.push({
          label: this.translateService.instant('licensed'),
          value: licenseCount.used,
        });
        this.statEntries.push({
          label: this.translateService.instant('licensesAvailable'),
          value: licenseCount.available,
        });
        this.statEntries.push({
          label: this.translateService.instant('licenseExpiryDate'),
          value: this.globalService.dateToFrChLocale(licenseCount.expiryDate),
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setActiveTab(tab: string) {
    if (this.activeTab === tab) {
      return;
    }
    this.activeTab = tab;
  }

  private onGridReady(event: GridReadyEvent<User>) {
    const {api} = event;
    this.subscriptions.push(I18n.getColumns(this.translateService, api));
    this.presetClosedFilter(api);
  }

  private presetClosedFilter(gridApi: GridApi) {
    const closedFilterModel = gridApi.getColumnFilterModel('closed');

    if (!closedFilterModel) {
      gridApi.setColumnFilterModel('closed', {values: ['false']})
        .then(() => gridApi.onFilterChanged());
    }
  }

  gridFilterReset(event: GridResetEvent) {
    this.presetClosedFilter(event.api);
  }

  private getUserRoleTypesAsCodeTable(): Observable<CodeTableEntry[]> {
    if (!this.userRoleTypesAsCodeTable || this.userRoleTypesAsCodeTable.length === 0) {
      this.userRoleTypesAsCodeTable = [
        UserRoleType.ADMINISTRATOR,
        UserRoleType.CAMPAIGNCREATOR,
        UserRoleType.DISTRIBUTOR,
        UserRoleType.READONLY,
        UserRoleType.FRONTCAMPAIGNCREATOR,
        UserRoleType.DECENTRALIZEDCAMPAIGNCREATOR,
      ].map(d => this.userRoleTypeToCodeTableEntry(d));
    }
    return of(this.userRoleTypesAsCodeTable);
  }

  private userRoleTypeToCodeTableEntry(role?: UserRoleType): CodeTableEntry | undefined {
    if (!role) {
      return undefined;
    }
    // id will be the hash of the role, so it will be unique
    return {
      id: hashCode(role),
      ident: role,
      name: this.translateService.instant(`role_${role.toLowerCase()}`),
      closed: false,
    } as CodeTableEntry;
  }
}

/**
 * Hash code for a string
 * @param str
 */
export function hashCode(str: string): number {
  return Array.from(str)
    .reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);
}
