import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'src/app/shared/shared.module';
import {HomeComponent} from './views/home/home.component';
import {LoginComponent} from './views/login/login.component';
import {LicenseComponent} from './views/license/license.component';
import {CallbackComponent} from './views/callback/callback.component';
import {ErrorCatalogueComponent} from './views/error-catalogue/error-catalogue.component';
import {ErrorOverviewComponent} from './views/error-overview/error-overview.component';
import {SettingsComponent} from './views/settings/settings.component';
import {RouterLink} from '@angular/router';
import {SalutationsComponent} from './views/settings/salutations/salutations.component';
import {SalutationDetailsComponent} from './views/settings/salutations/salutation-details/salutation-details.component';
import {TemplateDefaultsComponent} from './views/settings/template-defaults/template-defaults.component';
import {
  TemplateDefaultDetailsComponent
} from './views/settings/template-defaults/template-default-details/template-default-details.component';
import {SalutationPreviewComponent} from './views/settings/salutations/salutation-preview/salutation-preview.component';
import {IconsComponent} from './views/icons/icons.component';
import {SystemStatusComponent} from "./views/system-status/system-status.component";
import {ClientModule} from "../client/client.module";
import {PortfolioModule} from "../portfolio/portfolio.module";
import {ProductModule} from "../product/product.module";
import {BucketDetailsComponent} from "./views/settings/buckets/bucket-details/bucket-details.component";
import {BucketDialogComponent} from "./views/settings/buckets/bucket-dialog/bucket-dialog.component";
import {HomeBestActionsComponent} from "./views/home/home-best-actions/home-best-actions.component";
import {HomeChartComponent} from "./views/home/home-chart/home-chart.component";
import {DistributorModule} from "../distributor/distributor.module";
import {FilterConfigsComponent} from "./views/settings/filter-configs/filter-configs.component";
import {CcFrameworkComponent} from "./views/settings/cc-framework/cc-framework.component";
import {EmailDialogComponent} from "./views/settings/email-dialog/email-dialog.component";
import {
  FieldDefinitionDetailsComponent
} from "./views/settings/field-definitions/field-definition-details/field-definition-details.component";
import {
  FieldDefinitionDialogComponent
} from "./views/settings/field-definitions/field-definition-dialog/field-definition-dialog.component";
import {
  ContentDefinitionDetailsComponent
} from "./views/settings/content-definitions/content-definition-details/content-definition-details.component";
import {
  ContentDefinitionDialogComponent
} from "./views/settings/content-definitions/content-definition-dialog/content-definition-dialog.component";
import {FroalaEditorModule, FroalaViewModule} from "angular-froala-wysiwyg";
import {
  HubChannelDefinitionMappingDialogComponent
} from "./views/settings/content-definitions/hub-channel-definition-mapping-dialog/hub-channel-definition-mapping-dialog.component";
import {AppMessagesComponent} from "./views/settings/app-messages/app-messages.component";
import {
  AppMessageEditDialogComponent
} from "./views/settings/app-message-edit-dialog/app-message-edit-dialog.component";
import {
  AppMessageViewDialogComponent
} from "./views/settings/app-message-view-dialog/app-message-view-dialog.component";
import {
  AppMessageListDialogComponent
} from "./views/settings/app-message-list-dialog/app-message-list-dialog.component";
import {OutgoingMessagesComponent} from "./views/settings/outgoing-messages/outgoing-messages.component";
import {SentMessagesComponent} from "./views/settings/sent-messages/sent-messages.component";
import {
  MonitoringOutgoingMessagesComponent
} from "./views/settings/monitoring-outgoing-messages/monitoring-outgoing-messages.component";
import {LinkCreatorComponent} from "./views/link-creator/link-creator.component";
import {ReportCreatorComponent} from "./views/settings/report-request/report-creator/report-creator.component";
import {ReportBodyComponent} from "./views/settings/report-request/report-body/report-body.component";
import {ReportFilterComponent} from "./views/settings/report-request/report-filter/report-filter.component";
import {ReportRequestComponent} from "./views/settings/report-request/report-request.component";
import {ReportStatusListComponent} from "./views/settings/report-status-list/report-status-list.component";

/**
 * Core Module.
 * Contains core components Home, Login and Notifier.
 */
@NgModule({
  declarations: [
    AppMessageEditDialogComponent,
    AppMessageListDialogComponent,
    AppMessageViewDialogComponent,
    AppMessagesComponent,
    BucketDetailsComponent,
    BucketDialogComponent,
    CallbackComponent,
    CcFrameworkComponent,
    ContentDefinitionDetailsComponent,
    ContentDefinitionDialogComponent,
    EmailDialogComponent,
    ErrorCatalogueComponent,
    ErrorOverviewComponent,
    FieldDefinitionDetailsComponent,
    FieldDefinitionDialogComponent,
    FilterConfigsComponent,
    HomeBestActionsComponent,
    HomeChartComponent,
    HomeComponent,
    HubChannelDefinitionMappingDialogComponent,
    IconsComponent,
    LicenseComponent,
    LoginComponent,
    MonitoringOutgoingMessagesComponent,
    OutgoingMessagesComponent,
    SalutationDetailsComponent,
    SalutationPreviewComponent,
    SalutationsComponent,
    SentMessagesComponent,
    SettingsComponent,
    SystemStatusComponent,
    TemplateDefaultDetailsComponent,
    TemplateDefaultsComponent,
    LinkCreatorComponent,
    ReportCreatorComponent,
    ReportBodyComponent,
    ReportFilterComponent,
    ReportRequestComponent,
    ReportStatusListComponent,
  ],
  imports: [
    ClientModule,
    CommonModule,
    DistributorModule,
    FroalaEditorModule,
    FroalaViewModule,
    PortfolioModule,
    ProductModule,
    RouterLink,
    SharedModule,
  ],
  exports: [
    AppMessageViewDialogComponent,
  ],
})
export class CoreModule {
}
