import {ExportRequestBody, ExportRequestField} from "../../../../../api/core";

export type ReportMap = Record<string, any>;
export type ReportCommonOperators = 'eq'|'ne'|'in'|'nin';
export type ReportStringOperator = 'like';
export type ReportComparableOperator = 'lt'|'gt'|'gte'|'lte'
export type ReportOperators = ReportCommonOperators | ReportStringOperator | ReportComparableOperator;
export type ReportSchemaType = {
  [operator in ReportOperators]: string
}
export type ReportSchemaTypes = Record<string, Partial<ReportSchemaType>>;
export type ReportSchemaFilter = ExportRequestField;
export type ReportFilter = {
  field: string;
  value: string;
  filter: ReportSchemaFilter;
};
export type ReportSchemaEntity = {
  fields?: Record<string, string>;
  entities?: Record<string, string>;
  filters?: Record<string, ReportSchemaFilter>;
  sortBy?: ReportSchemaSort[];
  limit?: number;
};
export type ReportSchemaSort = {
  field: string;
  desc?: boolean;
}
export type ReportSchema = {
  masters: string[];
  types:  ReportSchemaTypes;
  entities: Record<string, ReportSchemaEntity>;
}
export type ReportEntity = {
  key: string;
  name: string;
  spec: ReportSchemaEntity;
}
export type ReportEntityBody = ReportEntity & {
  body: ExportRequestBody;
}

export const defaultReportSchema: ReportSchema = {
  "types": {
    "string": {
      "eq": "valueStr",
      "ne": "valueStr",
      "in": "valueStrList",
      "nin": "valueStrList",
      "like": "valueStr"
    },
    "number": {
      "eq": "valueInt",
      "ne": "valueInt",
      "lt": "valueInt",
      "lte": "valueInt",
      "gt": "valueInt",
      "gte": "valueInt",
      "in": "valueIntList",
      "nin": "valueIntList"
    },
    "decimal": {
      "eq": "valueNum",
      "ne": "valueNum",
      "lt": "valueNum",
      "lte": "valueNum",
      "gt": "valueNum",
      "gte": "valueNum"
    },
    "date": {
      "eq": "valueStr (yyyy-mm-dd)",
      "ne": "valueStr (yyyy-mm-dd)",
      "lt": "valueStr (yyyy-mm-dd)",
      "lte": "valueStr (yyyy-mm-dd)",
      "gt": "valueStr (yyyy-mm-dd)",
      "gte": "valueStr (yyyy-mm-dd)"
    },
    "datetime": {
      "eq": "valueStr (ISO_DATE_TIME: 2023-04-12T18:34:59.361+00:00)",
      "ne": "valueStr (ISO_DATE_TIME: 2023-04-12T18:34:59.361+00:00)",
      "lt": "valueStr (ISO_DATE_TIME: 2023-04-12T18:34:59.361+00:00)",
      "lte": "valueStr (ISO_DATE_TIME: 2023-04-12T18:34:59.361+00:00)",
      "gt": "valueStr (ISO_DATE_TIME: 2023-04-12T18:34:59.361+00:00)",
      "gte": "valueStr (ISO_DATE_TIME: 2023-04-12T18:34:59.361+00:00)"
    },
    "CampaignStatus": {
      "eq": "valueStr any of (DRAFT, LAUNCHED, FROZEN, CLOSED, TERMINATED)",
      "ne": "valueStr any of (DRAFT, LAUNCHED, FROZEN, CLOSED, TERMINATED)",
      "in": "valueIntList any of (DRAFT, LAUNCHED, FROZEN, CLOSED, TERMINATED)",
      "nin": "valueIntList any of (DRAFT, LAUNCHED, FROZEN, CLOSED, TERMINATED)"
    },
    "CampaignActionStatus": {
      "eq": "valueStr any of (PENDING, NO_ACTION, SENT, DUPLICATE_CLIENT)",
      "ne": "valueStr any of (PENDING, NO_ACTION, SENT, DUPLICATE_CLIENT)",
      "in": "valueIntList any of (PENDING, NO_ACTION, SENT, DUPLICATE_CLIENT)",
      "nin": "valueIntList any of (PENDING, NO_ACTION, SENT, DUPLICATE_CLIENT)"
    },
    "SuitabilityState": {
      "eq": "valueStr any of (OK, CONFIRM, RESTRICTED, NOT_OK, UNKNOWN, ERROR, PENDING)",
      "ne": "valueStr any of (OK, CONFIRM, RESTRICTED, NOT_OK, UNKNOWN, ERROR, PENDING)",
      "in": "valueIntList any of (OK, CONFIRM, RESTRICTED, NOT_OK, UNKNOWN, ERROR, PENDING)",
      "nin": "valueIntList any of (OK, CONFIRM, RESTRICTED, NOT_OK, UNKNOWN, ERROR, PENDING)"
    },
    "SuitabilityStateOrdinal": {
      "eq": "valueStr any of (OK, CONFIRM, RESTRICTED, NOT_OK, UNKNOWN, ERROR, PENDING)",
      "ne": "valueStr any of (OK, CONFIRM, RESTRICTED, NOT_OK, UNKNOWN, ERROR, PENDING)",
      "in": "valueStrList any of (OK, CONFIRM, RESTRICTED, NOT_OK, UNKNOWN, ERROR, PENDING)",
      "nin": "valueStrList any of (OK, CONFIRM, RESTRICTED, NOT_OK, UNKNOWN, ERROR, PENDING)"
    }
  },
  "entities": {
    "Campaign": {
      "fields": {
        "ident": "string",
        "name": "string",
        "status": "CampaignStatus",
        "validFrom": "date",
        "validTo": "date",
        "launchedAt": "date",
        "autoSyncContent": "boolean",
        "autoSyncBuyProducts": "boolean",
        "autoSyncSellProducts": "boolean",
        "autoSyncAllowedUsers": "boolean",
        "useCase": "Entity(UseCase.ident)"
      },
      "entities": {
        "story": "Story",
        "buyProducts": "Product[]",
        "sellProducts": "Product[]",
        "campaignPortfolios": "Portfolio[]",
        "contents": "Content[]"
      }
    },
    "Story": {
      "fields": {
        "ident": "string",
        "name": "string",
        "validFrom": "date",
        "validTo": "date",
        "info": "string",
        "hot": "boolean",
        "coverImage": "string",
        "errors": "string",
        "autoSyncContent": "boolean",
        "autoSyncBuyProducts": "boolean",
        "autoSyncSellProducts": "boolean",
        "autoSyncAllowedUsers": "boolean",
        "useCase": "Entity(UseCase.ident)",
        "author": "Entity(User.username)",
        "authorName": "Entity(User.fullname)"
      },
      "entities": {
        "campaigns": "Campaign[]",
        "buyProducts": "Product[]",
        "sellProducts": "Product[]",
        "tags": "Tag[]",
        "contents": "Content[]"
      }
    },
    "Product": {
      "fields": {
        "ident": "string",
        "name": "string",
        "isin": "string",
        "description": "string",
        "type": "Entity(ProductType.ident)"
      },
      "entities": {}
    },
    "Client": {
      "fields": {
        "ident": "string",
        "personNumber": "string",
        "birthYear": "number",
        "type": "Entity(ClientType.ident)",
        "gender": "Entity(Gender.ident)",
        "businessUnit": "Entity(BusinessUnit.ident)",
        "domicile": "Entity(Country.ident)"
      },
      "entities": {
        "portfolios": "Portfolio[]"
      }
    },
    "Portfolio": {
      "fields": {
        "ident": "string",
        "number": "string",
        "portfolioValue": "decimal",
        "returnValue": "decimal",
        "risk": "decimal",
        "riskSpreadMin": "decimal",
        "riskSpreadMax": "decimal",
        "optOut": "boolean",
        "type": "Entity(PortfolioType.ident)",
        "advisoryType": "Entity(AdvisoryType.ident)",
        "riskState": "Entity(RiskState.ident)",
        "strategy": "Entity(PortfolioStrategy.ident)",
        "referenceCurrency": "Entity(Currency.ident)",
        "preferredLanguage": "Entity(Language.ident)",
        "relationshipManager": "Entity(User.username)",
        "relationshipManagerName": "Entity(User.fullname)",
        "advisor": "Entity(User.username)",
        "advisorName": "Entity(User.fullname)"
      },
      "entities": {
        "clients": "Client[]",
        "actions": "Action[]"
      }
    },
    "Action": {
      "fields": {
        "status": "CampaignActionStatus",
        "executionDate": "date",
        "lastSuitabilityCheck": "datetime",
        "currentSuitabilityRequest": "datetime",
        "combinedSuitabilityState": "SuitabilityStateOrdinal",
        "clientUid": "Entity(Client.ident)",
        "clientRole": "Entity(ClientRole.full_name)",
        "assignee": "Entity(User.username)",
        "assigneeName": "Entity(User.fullname)",
        "sender": "Entity(User.username)",
        "senderName": "Entity(User.fullname)",
        "language": "Entity(Language.ident)",
        "portfolioIdent": "Entity(Portfolio.ident)",
        "portfolioNumber": "Entity(Portfolio.number)"
      },
      "entities": {
        "client": "Client",
        "content": "Content",
        "channel": "Channel",
        "suitabilities": "Suitability[]",
        "accessedDocuments": "ClientDocumentAccess[]"
      }
    },
    "Content": {
      "fields": {
        "video": "string",
        "language": "Entity(Language.ident)"
      },
      "entities": {
        "attachments": "ContentAttachments[]",
        "fields": "Fields[]"
      }
    },
    "ContentAttachment": {
      "fields": {
        "filename": "string",
        "source": "string"
      },
      "entities": {}
    },
    "Field": {
      "fields": {
        "type": "string",
        "value": "string",
        "isRichText": "boolean"
      },
      "entities": {}
    },
    "ContactChannel": {
      "fields": {
        "address": "string",
        "salutation": "string",
        "type": "Entity(ChannelType.ident)"
      },
      "entities": {}
    },
    "Suitability": {
      "fields": {
        "productIdent": "Entity(Product.ident)",
        "productIsin": "Entity(Product.isin)",
        "productName": "Entity(Product.name)"
      },
      "entities": {
        "status": "SuitabilityStatus[]"
      }
    },
    "SuitabilityStatus": {
      "fields": {
        "state": "SuitabilityStateOrdinal",
        "category": "string",
        "detail": "string"
      },
      "entities": {}
    },
    "ClientDocumentAccess": {
      "fields": {
        "fileName": "string",
        "fileType": "string",
        "accessTime": "datetime"
      },
      "entities": {}
    },
    "Tag": {
      "fields": {
        "name": "string"
      },
      "entities": {}
    }
  },
  "masters": [
    "campaign",
    "story",
    "product",
    "client",
    "portfolio",
    "action"
  ]
};


export function getReportEntityType(name: string): string {
  return name.endsWith('[]') ? name.slice(0, -2) : name;
}
