<div class="tab-group">
  <mat-button-toggle-group name="movements" [(ngModel)]="dataType">
    <mat-button-toggle [value]="'top'">
      {{ 'monitoringCampaignTop5' | translate }}
    </mat-button-toggle>
    <mat-button-toggle [value]="'best'">
      {{ 'monitoringCampaignBest' | translate }}
    </mat-button-toggle>
    <mat-button-toggle [value]="'all'">
      {{ 'monitoringCampaignAll' | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
<app-grid
  #grid_all
  tableId="monitoring-campaign"
  [columnDefs]="columnDefs"
  [gridOptions]="gridOptions"
  *ngIf="dataType === 'all'"
  [refreshGridDataMethod]="gridRefresh"
></app-grid>
<app-grid
  #grid_best
  tableId="monitoring-campaign-best"
  [columnDefs]="columnDefs"
  [gridOptions]="gridOptionsBest"
  *ngIf="dataType === 'best'"
  [refreshGridDataMethod]="gridRefresh"
></app-grid>
<app-grid
  #grid_top
  tableId="monitoring-campaign-top"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [gridOptions]="gridOptionsTop"
  *ngIf="dataType === 'top'"
></app-grid>
