import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  CampaignProductType,
  GridFilterOptionsParams,
  MonitoringCampaign,
  MonitoringService,
  PortfolioMovementSummary,
  PortfolioService,
  UserInfo,
} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {GridComponent} from 'src/app/shared/grid/grid.component';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {EMonitoringTypes, EViewRoutes} from 'src/app/util/enum';
import {
  genBooleanColumn,
  genDateColumn,
  genEnumColumn,
  genNumberColumn,
  genPercentageNumberColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter,
  genUserEnumColumn,
} from 'src/app/util/grid/grid-renderer.util';
import {I18n} from '../../../services/i18n.service';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {formatDate} from '@angular/common';
import {LabelBuilder} from "../../../util/label-builder";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-monitoring-campaign-overview',
  templateUrl: './monitoring-campaign-overview.component.html',
})
export class MonitoringCampaignOverviewComponent implements OnInit, OnDestroy {
  @Input() monitoringCampaign: MonitoringCampaign;
  @ViewChild('grid') grid: GridComponent;
  @ViewChild('movementsGrid') movementsGrid: GridComponent;

  _cached = true;
  get cached() {
    return this._cached;
  }

  set cached(value: boolean) {
    if (this._cached === value) {
      return;
    }
    this._cached = value;
    this.updateData();
  }

  campaignsRelationshipManagers: UserInfo[];
  data: GridDataProvider;

  get createdAt(): string {
    if (!this.cached || !this.monitoringCampaign?.createdAt) return '';
    return [
      '',
      this.globalService.dateToFrChLocale(this.monitoringCampaign.createdAt),
      this.globalService.timeToFrChLocale(this.monitoringCampaign.createdAt),
    ].join(' ');
  }

  columnDefs: ColDef[] = [
    {
      ...genUserEnumColumn(
        'name',
        I18n.getColName('name'),
        this.fetchCampaignRelationshipManagers.bind(this),
        () => this.campaignsRelationshipManagers,
      ),
      valueFormatter: (r) => (r.data.username.trim()) ? `${r.data.name} (${r.data.username})` : r.data.name,
      floatingFilter: false,
      sortable: false
    },
    genNumberColumn(
      'portfolioCount',
      I18n.getColName('portfolios'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    {
      ...genPercentageNumberColumn(
        'clientContactsPercentage',
        I18n.getColName('contacted'),
        this.globalService,
      ),
    },
    genNumberColumn(
      'clientContacts',
      I18n.getColName('contacts'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    {
      ...genPercentageNumberColumn(
        'executionsPercentage',
        I18n.getColName('executedRate'),
        this.globalService,
      ),
      hide: false,
    },
    genNumberColumn(
      'executions',
      I18n.getColName('executions'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0),
    ),
    {
      ...genPercentageNumberColumn(
        'noActionsPercentage',
        I18n.getColName('noActionsRate'),
        this.globalService,
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'noActions',
        I18n.getColName('noActions'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true,
    },
    {
      ...genPercentageNumberColumn(
        'pendingActionsPercentage',
        I18n.getColName('pendingActionsRate'),
        this.globalService,
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'pendingActions',
        I18n.getColName('pendingActions'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0)
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'sellVolume',
        this.labelBuilder.labelWithCurrency('sellTradingVolume'),
        this.globalService
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'buyVolume',
        this.labelBuilder.labelWithCurrency('buyTradingVolume'),
        this.globalService
      ),
      hide: true,
    },
    {
      ...genNumberColumn(
        'totalVolume',
        this.labelBuilder.labelWithCurrency('totalTradingVolume'),
        this.globalService
      ),
      hide: true,
    },
    {
      ...genPercentageNumberColumn(
        'clientViewedPercentage',
        I18n.getColName('viewedRate'),
        this.globalService,
      ),
    },
    genNumberColumn(
      'clientViewed',
      I18n.getColName('viewed'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    {
      ...genNumberColumn(
        'clientCount',
        I18n.getColName('clients'),
        this.globalService,
        (d) => this.globalService.getFormattedValue(d.value, 0),
      ),
      hide: true,
    },
    {
      ...genBooleanColumn(
        'participated',
        I18n.getColName('participated'),
        this.translateService,
      ),
      hide: true,
    }
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => {
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
      this.gridApi = event.api;
    }
  };

  movementsSummaryData: PortfolioMovementSummary[];
  movementsSummaryColumnDefs: ColDef[] = [
    genTextColumn('productName', this.translateService.instant('product')),
    genEnumColumn({
      field: 'tradingType',
      values: [
        CampaignProductType.BUY,
        CampaignProductType.SELL,
      ],
      headerName: this.translateService.instant('type')
    }),
    genNumberColumn(
      'transactions',
      this.translateService.instant('portfolioMovements'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    genNumberColumn(
      'tradingVolume',
      this.labelBuilder.labelWithCurrency('value'),
      this.globalService
    ),
  ];
  movementsSummaryGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => {
      this.gridApiProducts = event.api;
    }
  };

  movementsData: GridDataProvider;
  subscriptions: Subscription[] = [];

  movementsColumnDefs: ColDef[] = [
    genTextColumn(
      'productName',
      this.translateService.instant('product'),
      null,
      {customPath: 'product.name'}
    ),
    genTextColumnWithAutoCompleteFilter({
      // sven: https://github.com/confinale/aspark/issues/8436
      field: 'portfolioNumber',
      headerName: this.translateService.instant('portfolio'),
      autoCompleteParams: {
        apiMethod: (data: GridFilterOptionsParams) => this.portfolioService.getGridFilterOptions(data),
        autoCompleteField: 'number',
      },
      filterParamsInfo: { customPath: 'campaignPortfolio.portfolio.number' }
    }),
    genDateColumn({
      field: 'launchedAt',
      headerName: this.translateService.instant('timestamp'),
      dateFormatter: (data) => formatDate(data.value, 'dd.MM.yyyy - HH:mm:ss', 'en-US'),
    }),
    genEnumColumn({
      field: 'tradingType',
      values: [
        CampaignProductType.BUY,
        CampaignProductType.SELL,
      ],
      headerName: this.translateService.instant('type')
    }),
    genNumberColumn(
      'tradingVolume',
      this.labelBuilder.labelWithCurrency('value'),
      this.globalService
    ),
  ];
  movementsGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => {
      this.gridApiMovements = event.api;
    }
  };

  gridApi: GridApi;
  gridApiMovements: GridApi;
  gridApiProducts: GridApi;
  currentMovementTab = 0;
  isDecentralized = false;

  constructor(
    protected translateService: TranslateService,
    protected monitoringService: MonitoringService,
    protected globalService: GlobalService,
    protected labelBuilder: LabelBuilder,
    protected activatedRoute : ActivatedRoute,
    protected portfolioService: PortfolioService,
  ) {
    activatedRoute.data.subscribe(data => {
      this.isDecentralized = data.viewRoute === EViewRoutes.monitoringCampaignDecentralizedOverview;
    });
  }

  ngOnInit(): void {
    this.updateData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(d => d.unsubscribe());
  }

  get monitoringTypes() {
    return EMonitoringTypes;
  }

  private updateData() {
    if (this.isDecentralized) {
      this.cached = false;
    }
    this.monitoringService.getCampaignStats(
      this.monitoringCampaign.id,
      this.cached,
    ).subscribe(stats => {
      this.monitoringCampaign = stats;
    });
    this.data = this.monitoringService.getCampaignDetails.bind(
      this.monitoringService,
      this.monitoringCampaign.id,
      this.cached,
    );
    this.movementsData =
      this.monitoringService.getCampaignMovements.bind(
        this.monitoringService,
        this.monitoringCampaign.id,
        this.cached
      );
    this.monitoringService.getCampaignProducts(
      this.monitoringCampaign.id,
      this.cached,
    ).subscribe(rows => {
      this.movementsSummaryData = rows;
    });
    setTimeout(() => {
      [
        {grid: this.grid, api: this.gridApi},
        {grid: this.movementsGrid, api: this.gridApiMovements},
      ]
        .filter(({grid, api}) => grid && api)
        .forEach(({grid}) => {
          grid.initializeDataSource(true);
          grid.refresh();
        });

    }, 0);
  }

  private fetchCampaignRelationshipManagers(params: any) {
    this.monitoringService.getCampaignRelationshipManagers(this.monitoringCampaign.id, this.cached)
      .subscribe((users) => {
        this.campaignsRelationshipManagers = users;
        params.success(users.map((d) => d.username));
      });
  }
}
