<form [formGroup]="form" class="modal-form">
  <div class="row row-double">
    <mat-form-field>
      <mat-label>Ident</mat-label>
      <input type="text" matInput formControlName="ident" />
    </mat-form-field>
    <div class="buttons">
      <button type="button" mat-raised-button color="primary" (click)="onSend()" [disabled]="!form.valid">
        <app-config-icon iconIdentifier="send"></app-config-icon>
        {{'send' | translate}}
      </button>
      <button type="button" mat-raised-button (click)="onClearFields()">
        <app-config-icon iconIdentifier="clear"></app-config-icon>
        {{'clear' | translate}}
      </button>
      <button type="button" mat-raised-button (click)="onIdentNow()">
        <app-config-icon iconIdentifier="schedule"></app-config-icon>
        {{'now' | translate}}
      </button>
    </div>
  </div>
  <div class="row row-body">
    <mat-form-field>
      <mat-label>Body</mat-label>
      <textarea type="text" matInput formControlName="body"></textarea>
    </mat-form-field>
  </div>
</form>
