<form [formGroup]="formGroup" class="modal-form">
  <div class="row row-double">
    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select formControlName="type" (valueChange)="onTypeChanged($event)">
        <mat-option [value]="'TREE'">TREE</mat-option>
        <mat-option [value]="'PLAIN'">PLAIN</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Master</mat-label>
      <mat-select formControlName="master" (valueChange)="onMasterChanged($event)">
        <mat-option *ngFor="let m of masters" [value]="m.value">
          {{m.text}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="buttons">
      <button type="button" mat-raised-button (click)="onCopyRequest()">
        <app-config-icon iconIdentifier="copy"></app-config-icon>
        {{'copy' | translate}}
      </button>
    </div>
  </div>
  <div class="row row-body">
      <app-report-body
        [entity]="masterEntity"
        [originalSchema]="schema"
        [schema]="rootSchema"
        [body]="root.spec"
        *ngIf="root.master"></app-report-body>
  </div>
</form>
